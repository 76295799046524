<script setup>
  import { Role } from '@/store/chatStore';
  import ChatBubbleAvatar from '@/components/chat/ChatBubbleAvatar.vue';
  import ChatBubbleMessage from '@/components/chat/ChatBubbleMessage.vue';
  import SvgLoading from '@/components/common/svg/svgLoading.vue';

  const props = defineProps(['messageRole', 'text', 'sources', 'index', 'status']);
</script>
<template>
  <div
    :class="props.messageRole === Role.USER ? 'flex-row-reverse self-end' : ''"
    class="bubble flex gap-4 max-w-4/5"
  >
    <ChatBubbleAvatar :messageRole="props.messageRole" />
    <SvgLoading v-if="props.status === 'loading'" />
    <ChatBubbleMessage
      :messageRole="props.messageRole"
      :text="props.text"
      :sources="props.sources"
      :index="index"
    />
  </div>
</template>
