import { useAuthStore } from '@/store/authStore.js';
const isAuthenticationRequired = import.meta.env.VITE_AUTHENTICATION_DISABLED;

const fetcher = async (requestUrl, requestOptions = {}) => {
  const authStore = useAuthStore();
  let response;
  try {
    if (isAuthenticationRequired === 'false') {
      requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: `Bearer ${authStore.oauth2Token.accessToken}`,
      };
      response = await authStore.fetchWrapper.fetch(requestUrl, requestOptions);
    } else {
      response = await fetch(requestUrl, requestOptions);
    }
    return response;
  } catch (error) {
    console.error('Error in the request using FetchWrapper():', error.message);
    throw error;
  }
}

export { fetcher }
