import { useChatStore, Role, Status, createMessage } from '@/store/chatStore';
import { useAppStore } from '@/store/appStore';
import { useFeaturesUrl } from '@/utils/useFeaturesUrl';
import { handleDocumentSearchData } from '@/utils/handleDocumentSearchData';
import { handleChatData } from '@/utils/handleChatData';
import { fetcher } from '@/utils/useFetcher';

export async function sendChatMessage(message) {
  const appStore = useAppStore();
  const plugin = appStore.currentPlugin.name;
  const language = appStore.currentLanguage.name;

  const chatStore = useChatStore();
  const chatHistory = chatStore.chat.messages;

  // Filter the chat history so that only entries with the role "USER" and "ASSISTANT" are retained
  const userChatHistory = chatHistory.filter((entry) => entry.role === 'user' || entry.role === 'assistant');

  let requestUrl = useFeaturesUrl(appStore.currentPlugin.feature.type);

  if (appStore.currentPlugin.feature.type === 'chat') {
    requestUrl.searchParams.set('message', message);
  } else if (appStore.currentPlugin.feature.type === 'document_search') {
    requestUrl.searchParams.set('question', message);
  }
  requestUrl.searchParams.set('language', language.toLowerCase());
  requestUrl.searchParams.set('plugin', plugin);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify({
      chat_history: userChatHistory.slice(-10).map(({ role, content }) => ({ role, content })),
    }),
  };

  try {
    const response = await fetcher(requestUrl, requestOptions);

    const message = createMessage({
      role: Role.ASSISTANT,
      status: Status.LOADING,
      plugin: appStore.currentPlugin.name,
    });

    chatStore.addMessage(message);

    if (appStore.currentPlugin.feature.type === 'document_search') {
      await handleDocumentSearchData(response, message.id);
    } else {
      await handleChatData(response, message.id);
    }

    chatStore.setCurrentStatus(message.id, Status.FINISHED);

    return true;
  } catch (error) {
    console.error('Error:', error);

    return false;
  }
}
