<template>
  <div class="h-6 w-6 flex justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5 3.01855C8.31788 3.01855 8.15017 3.11757 8.0622 3.27704C7.97423 3.4365 7.97992 3.63118 8.07705 3.78523L9 5.24902V10.3066C8.8867 10.4045 8.72642 10.5461 8.53474 10.7241C8.15051 11.0809 7.63685 11.5872 7.12168 12.1834C6.60785 12.778 6.08353 13.4724 5.68554 14.2058C5.29003 14.9346 5 15.7371 5 16.5385C5 16.8147 5.22386 17.0385 5.5 17.0385H11.527V20.9405C11.527 21.2167 11.7509 21.4405 12.027 21.4405C12.3032 21.4405 12.527 21.2167 12.527 20.9405V17.0385H18.5C18.7761 17.0385 19 16.8147 19 16.5385C19 15.7353 18.7101 14.9319 18.3145 14.2025C17.9165 13.4686 17.3921 12.7744 16.8782 12.1802C16.3629 11.5845 15.8492 11.0789 15.4649 10.7227C15.2735 10.5451 15.1133 10.4039 15 10.3063V5.24902L15.9229 3.78523C16.0201 3.63118 16.0258 3.4365 15.9378 3.27704C15.8498 3.11757 15.6821 3.01855 15.5 3.01855H8.5ZM9.81413 10.9276L9.81576 10.9262L9.81587 10.9261C9.93238 10.8312 10 10.6889 10 10.5386V5.10455C10 5.01017 9.97329 4.91772 9.92295 4.83788L9.40635 4.01855H14.5937L14.0771 4.83788C14.0267 4.91772 14 5.01017 14 5.10455V10.5386C14 10.6891 14.0678 10.8316 14.1846 10.9265L14.1847 10.9266L14.1848 10.9267L14.1863 10.9279L14.1936 10.9339L14.2244 10.9594C14.2518 10.9824 14.2928 11.017 14.3455 11.0624C14.4509 11.1531 14.6025 11.2868 14.7851 11.456C15.1508 11.7951 15.6371 12.2739 16.1218 12.8344C16.6079 13.3964 17.0835 14.0304 17.4355 14.6793C17.6895 15.1476 17.8694 15.6059 17.9509 16.0385H6.04938C6.13103 15.6076 6.31072 15.1503 6.56446 14.6827C6.91647 14.0341 7.39215 13.3998 7.87832 12.8372C8.36315 12.2761 8.84949 11.7965 9.21526 11.4568C9.39782 11.2873 9.54952 11.1533 9.6549 11.0623C9.70757 11.0169 9.74861 10.9822 9.77608 10.9592L9.80684 10.9336L9.81413 10.9276Z"
        fill="#F7F7F8"
      />
    </svg>
  </div>
</template>
