<script setup>
  import { useAppStore } from '@/store/appStore';
  import { storeToRefs } from 'pinia';

  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const props = defineProps(['menuVisible']);
  const emit = defineEmits(['closeMenu']);

  const { availableLanguages, currentLanguage } = storeToRefs(useAppStore());

  const handleLanguageChange = (selectedLanguageId, availableLanguages, currentLanguage) => {
    const language = availableLanguages.value.find((lang) => lang.id === selectedLanguageId);
    currentLanguage.value = language;
  };

  const handleLanguageSelection = (selectedLanguageId) => {
    handleLanguageChange(selectedLanguageId, availableLanguages, currentLanguage);
    emit('closeMenu');
  };
  const isSelected = (currentLanguageId, selectedLanguageId) =>
    currentLanguageId === selectedLanguageId;

  function debounce(func, delay) {
    let timeoutId = null;

    const debounced = function (...args) {
      const later = () => {
        timeoutId = null;
        func.apply(this, args);
      };

      clearTimeout(timeoutId);
      timeoutId = setTimeout(later, delay);
    };

    return debounced;
  }

  const debouncedExecuteFunction = debounce(handleLanguageSelection, 1000);
</script>

<template>
  <Transition>
    <form
      v-show="props.menuVisible"
      role="menu"
      aria-labelledby="menuButton"
      aria-orientation="vertical"
      class="absolute bottom-full p-3 bg-[#444444] w-full box- z-10 rounded-md shadow-lg ring-1 ring-neutral-600 focus:outline-none"
    >
      <label
        v-for="(language, index) in availableLanguages"
        :for="language.id"
        :key="language.id"
        :id="`language-${index}`"
        class="block px-4 py-3 text-sm h-11 text-zinc-100 rounded-lg transition-colors duration-200 cursor-pointer"
        :class="{
          'font-bold bg-[#565656] rounded-lg': isSelected(currentLanguage.id, language.id),
          'hover:bg-[#565656] hover:bg-opacity-50': !isSelected(currentLanguage.id, language.id),
        }"
        role="menuitem"
      >
        <input
          :id="language.id"
          type="radio"
          @change.stop.prevent="debouncedExecuteFunction(language.id)"
          name="language"
          class="sr-only"
        />
        <span class="float-left">
          {{ t(language.name) }}
        </span>
      </label>
    </form>
  </Transition>
</template>

<style scoped>
  label:not(:last-of-type) {
    margin-bottom: 4px;
  }

  label:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
