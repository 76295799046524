const FEATURES = {
  DOCUMENT_SEARCH: 'document_search',
  CHAT: 'chat',
  CHAT_SUMMARY: 'chat_summary',
};

/**
 * Returns the request URL based on the feature type and parameters.
 *
 * @param {string} feature - The type of feature.
 * @param {URLSearchParams} params - The parameters for the request.
 * @returns {URL} - The request URL.
 */
function useFeaturesUrl(feature, params = {}) {
  const apiUrl = import.meta.env.VITE_API_URL;
  const apiVersion = import.meta.env.VITE_API_VERSION;

  let requestUrl = new URL(`/api/${apiVersion}/features/${feature}`, apiUrl);

  requestUrl.search = new URLSearchParams(params);

  return requestUrl;
}

export { FEATURES, useFeaturesUrl };
