<script setup>
  import { RouterLink, useRoute } from 'vue-router';
  import SvgVodafoneAssist from '@/components/common/svg/svgVodafoneAssist.vue';
  import SvgVodafone from '@/components/common/svg/svgVodafone.vue';

  const route = useRoute();
  // To hide VodafoneAssist/vodafone logos in the mobile screens if it's home page and to keep it inside all other pages
  const isHomePage = route.path === '/';
</script>

<template>
  <nav class="flex justify-between items-center py-10 px-10 md:px-5 z-10">
    <slot name="navSectionLeft"></slot>
    <RouterLink
      to="/"
      class="flex items-center justify-center gap-2 flex-[2_1_0]"
      :class="{
        'md:hidden': isHomePage,
      }"
    >
      <SvgVodafoneAssist />
      <h1 class="font-VFRegular text-2xl vodafoneAssist">
        Vodafone <span class="font-bold">Assist </span>
      </h1>
      <div class="border-black border-l-2 pl-2"><SvgVodafone /></div>
    </RouterLink>

    <slot name="plugin"></slot>

    <slot name="navSectionRight"> </slot>
  </nav>
</template>

<style>
  nav {
    container-type: inline-size;
  }

  @container (max-width: 750px) {
    .vodafoneAssist {
      display: none;
    }
  }
</style>
