<template>
  <div class="h-4.5 w-4.5 flex justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 3.12591C2 2.50424 2.50424 2 3.12591 2H9.62409C10.2458 2 10.75 2.50424 10.75 3.12591V6.25H8.37591C7.2018 6.25 6.25 7.2018 6.25 8.37591V10.75H3.12591C2.50424 10.75 2 10.2458 2 9.62409V3.12591ZM6.25 11.75H3.12591C1.95196 11.75 1 10.798 1 9.62409V3.12591C1 1.95196 1.95196 1 3.12591 1H9.62409C10.798 1 11.75 1.95196 11.75 3.12591V6.25H14.8741C16.0482 6.25 17 7.2018 17 8.37591V14.8741C17 16.0482 16.0482 17 14.8741 17H8.37591C7.2018 17 6.25 16.0482 6.25 14.8741V11.75ZM7.25 8.37591C7.25 7.75409 7.75409 7.25 8.37591 7.25H14.8741C15.4959 7.25 16 7.75409 16 8.37591V14.8741C16 15.4959 15.4959 16 14.8741 16H8.37591C7.75409 16 7.25 15.4959 7.25 14.8741V8.37591Z"
        fill="#3A3A3A"
      />
    </svg>
  </div>
</template>
