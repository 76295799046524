<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5918 3C2.45305 3 1.5293 3.92375 1.5293 5.0625L1.5293 5.06338L1.53809 8.8125L1.53809 8.81296C1.53834 9.9515 2.46199 10.875 3.60059 10.875H3.7793V12.4541C3.7793 12.6071 3.87222 12.7447 4.01411 12.8019C4.15599 12.8591 4.31839 12.8244 4.42448 12.7142L6.1947 10.875H6.7793V12.1201C6.7793 13.304 7.69554 14.2354 8.8418 14.2354H11.8699L13.6431 16.0747C13.7492 16.1848 13.9116 16.2194 14.0534 16.1622C14.1952 16.105 14.2881 15.9674 14.2881 15.8145V14.2354H14.4492C15.588 14.2354 16.5117 13.3116 16.5117 12.1729V6.36328H11.2383V5.0625C11.2383 3.92418 10.3179 3 9.17578 3H3.5918ZM7.5293 12.1201V10.5V8.37012C7.5293 7.71863 8.08763 7.13305 8.80015 7.111C8.81383 7.11251 8.82772 7.11328 8.8418 7.11328H15.7617V12.1729C15.7617 12.8974 15.1738 13.4854 14.4492 13.4854H13.9131C13.706 13.4854 13.5381 13.6532 13.5381 13.8604V14.8851L12.2993 13.6001C12.2286 13.5268 12.1311 13.4854 12.0293 13.4854H8.8418C8.12477 13.4854 7.5293 12.905 7.5293 12.1201ZM8.8418 6.36035C8.85775 6.36035 8.87347 6.36135 8.8889 6.36328H10.4883V5.0625C10.4883 4.33754 9.90282 3.75 9.17578 3.75H3.5918C2.8674 3.75 2.27952 4.33774 2.2793 5.06209V5.0625L2.28809 8.8125C2.28809 9.53703 2.87605 10.125 3.60059 10.125H4.1543C4.3614 10.125 4.5293 10.2929 4.5293 10.5V11.5237L5.76498 10.2399C5.83567 10.1665 5.93322 10.125 6.03516 10.125H6.7793V8.37012C6.7793 7.27643 7.7108 6.36035 8.8418 6.36035ZM11.7188 9.86426C11.3388 9.86426 11.0303 10.1728 11.0303 10.5527C11.0303 10.9327 11.3388 11.2412 11.7188 11.2412C12.0987 11.2412 12.4072 10.9327 12.4072 10.5527C12.4072 10.1728 12.0987 9.86426 11.7188 9.86426ZM13.9688 9.86426C13.5888 9.86426 13.2803 10.1728 13.2803 10.5527C13.2803 10.9327 13.5888 11.2412 13.9688 11.2412C14.3487 11.2412 14.6572 10.9327 14.6572 10.5527C14.6572 10.1728 14.3487 9.86426 13.9688 9.86426ZM9.45996 9.86426C9.08 9.86426 8.77148 10.1728 8.77148 10.5527C8.77148 10.9327 9.08 11.2412 9.45996 11.2412C9.83992 11.2412 10.1484 10.9327 10.1484 10.5527C10.1484 10.1728 9.83992 9.86426 9.45996 9.86426Z"
      fill="currentColor"
    />
  </svg>
</template>
