import { defineStore } from 'pinia';
import { ref } from "vue";

export const useOnboardingStore = defineStore(
  'onboardingStore',
  () => {
    const showOnboarding = ref(false);

    const tooltips = ref([
      {
        id: 'pluginSelection',
        shown: false,
      },
      {
        id: 'followUpQuestions',
        shown: false,
        previous: 'pluginSelection',
      },
      {
        id: 'sourceIndicator',
        shown: false,
        previous: 'followUpQuestions',
      },
      {
        id: 'chatPinning',
        shown: false,
        previous: 'pluginSelection',
      },
      {
        id: 'moreOptions',
        shown: false,
        previous: 'chatPinning',
      },
      {
        id: 'newChatHintHeading',
        shown: false,
        previous: 'moreOptions',
      },
    ]);

    const setOnboardingShown = (value) => {
      showOnboarding.value = value;
    };

    const shouldShowOnboarding = () => {
      return !showOnboarding.value;
    };

    const setTooltipShown = (id) => {
      const tooltip = tooltips.value.find((tooltip) => tooltip.id === id);
      if (tooltip) {
        tooltip.shown = true;
      }
    }

    const getTooltips = () => {
      return tooltips.value;
    }

    return {
      setOnboardingShown,
      shouldShowOnboarding,
      getTooltips,
      setTooltipShown,
      showOnboarding,
      tooltips,
    };
  },
  { persist: true },
);
