<script setup>
  import { ref } from 'vue';
  import PagesMenu from './PagesMenu.vue';
  import SvgFAQ from './svg/svgFAQ.vue';
  const menuVisible = ref(false);

  const toggleMenu = () => {
    menuVisible.value = !menuVisible.value;
  };

  const closeMenu = () => {
    menuVisible.value = false;
  };
</script>

<template>
  <div class="w-11 h-11 absolute right-10 bottom-10 bg-white rounded-lg border shadow-md xl:hidden">
    <button @click="toggleMenu" class="p-3" type="button" id="menuButton" aria-haspopup="true">
      <SvgFAQ></SvgFAQ>
    </button>
    <PagesMenu :menuVisible="menuVisible" @closeMenu="closeMenu" class="bottom-14 right-0 w-60" />
  </div>
</template>
