<script setup>
  // Imports
  import { ref, watch, nextTick } from 'vue';
  import { FeatureToggleComponent as feature } from 'vue-feature-toggle';
  import { useChatStore } from '@/store/chatStore.js';
  import { useChatListStore } from '@/store/chatListStore';
  import { useAppStore } from '@/store/appStore.js';
  import ContextMenu from '@/components/common/MenuOption.vue';
  import SvgDelete from '@/components/common/svg/svgDelete.vue';
  import SvgPin from '@/components/common/svg/svgPin.vue';
  import SvgUnPin from '@/components/common/svg/svgUnPin.vue';
  import { usePluginStore } from '@/store/pluginStore.js';
  import Tooltip from '@/components/common/tooltip.vue';
  import { useI18n } from 'vue-i18n';
  import TooltipContent from '@/components/common/TooltipContent.vue';
  import OnboardingTooltip from '@/components/common/OnboardingTooltip.vue';

  // Initialisation
  const { t, locale } = useI18n();
  const appStore = useAppStore();
  const chatStore = useChatStore();
  const chatListStore = useChatListStore();
  const pluginStore = usePluginStore();
  const props = defineProps(['chat', 'maxPinnedChats']);

  // data
  const isEditing = ref(false);
  const editedName = ref('');
  const editInput = ref(null);

  const contextMenuActions = ref([
    { label: t('rename'), action: 'rename' },
    { label: t('deleteChat'), action: 'delete' },
  ]);

  // Watcher for language changes
  watch(locale, () => {
    contextMenuActions.value = [
      { label: t('rename'), action: 'rename' },
      { label: t('deleteChat'), action: 'delete' },
    ];
  });

  // actions
  function handleActionClick(action) {
    if (action === 'rename') {
      chatStore.chat = props.chat;
      appStore.currentChat = props.chat;
      closeMenuOption();
      isEditing.value = true;
      editedName.value = props.chat.name;
      if (editInput.value) {
        editInput.value.focus();
      }
    } else if (action === 'delete') {
      chatListStore.removeChat(props.chat.id);
    }
  }

  // Context menu
  const showMenu = ref(false);
  const menuX = ref(0);
  const menuY = ref(0);

  const showMenuOption = (event) => {
    event.preventDefault();
    showMenu.value = true;
    menuX.value = event.clientX;
    menuY.value = event.clientY;
  };

  const closeMenuOption = () => {
    showMenu.value = false;
  };

  // Chat actions
  const handleClick = () => {
    chatStore.chat = props.chat;
    appStore.currentChat = props.chat;
    if (props.chat.messages?.length > 0) {
      appStore.currentPlugin = pluginStore.plugins.find(
        (plugin) => plugin.name === props.chat.messages[0].plugin,
      );
    }
  };
  const handlePinClick = () => {
    chatStore.pinChat(props.chat.id, props.maxPinnedChats);
  };

  // Saving and cancelling editing
  const saveChanges = () => {
    const newChat = { ...props.chat };
    newChat.name = editedName.value;
    isEditing.value = false;
    chatStore.changeName(newChat.name);

    return newChat;
  };

  const cancelEditing = () => {
    isEditing.value = false;
  };

  // Reference to the input field
  const editInputRef = (el) => {
    editInput.value = el;
    if (isEditing.value && el) {
      nextTick(() => {
        el.focus();
      });
    }
  };
</script>

<template>
  <!-- Chat-Button -->
  <div>
    <button
      @click="handleClick()"
      class="btn btn-ghost hover:bg-secondary w-full flex text-neutral-100 font-bold mb-2 rounded-lg justify-between items-center p-4"
      :class="{ 'bg-secondary': appStore.currentChat.id === props.chat.id }"
    >
      <div class="flex items-center w-full h-full">
        <!-- Input field for editing the chat name -->

        <input
          v-if="isEditing"
          v-model="editedName"
          @keyup.enter="saveChanges"
          @blur="cancelEditing"
          class="basis-9/12 bg-transparent border border-white outline-none overflow-hidden whitespace-nowrap text-left flex-grow font-normal leading-[17.50px] text-sm font-['VodafoneRg']"
          :ref="editInputRef"
          autofocus
        />
        <!-- Display of the chat name -->
        <span
          v-else
          class="overflow-hidden whitespace-nowrap text-left flex-grow font-normal leading-[17.50px] text-sm font-['VodafoneRg']"
          style="flex-basis: 75%"
          >{{
            isEditing
              ? editedName
              : props.chat.name === 'New Chat'
                ? $tm('newChat')
                : props.chat.name
          }}</span
        >

        <!-- Pin-Button -->
        <feature name="pin">
          <OnboardingTooltip
            tooltip_id="chatPinning"
            :heading="t(`sidebar.pinningHintHeading`)"
            :content="t(`sidebar.pinningHintContent`)"
            placement="right"
          >
            <button @click.stop="handlePinClick()" class="ml-2.5" id="pinChatButton">
              <SvgPin v-if="!props.chat.pinned" />
              <SvgUnPin v-else />
              <tooltip
                v-if="chatListStore.chats.filter((chat) => chat.pinned).length >= maxPinnedChats"
                tooltip_id="chatPinningMaxReached"
                :options="{ placement: 'right' }"
              >
                <TooltipContent :content="t(`sidebar.maxPinnedChatsTooltip`, [maxPinnedChats])" />
              </tooltip>
            </button>
          </OnboardingTooltip>
        </feature>
        <!-- Delete/Rename-Button -->
        <feature name="context">
          <OnboardingTooltip
            tooltip_id="moreOptions"
            :heading="t(`sidebar.moreOptionsHintHeading`)"
            :content="t(`sidebar.moreOptionsHintContent`)"
            placement="right"
          >
            <button @click.stop="showMenuOption" class="ml-2 hover:shadow-inner">
              <SvgDelete />
            </button>
          </OnboardingTooltip>
        </feature>
      </div>
    </button>

    <!-- Overlay to close the menu -->
    <div
      class="fixed top-0 left-0 w-full h-full bg-transparent z-50"
      @click="closeMenuOption"
      v-if="showMenu"
    />

    <!-- User-defined context menu -->
    <ContextMenu
      v-if="showMenu"
      :actions="contextMenuActions"
      @action-clicked="handleActionClick"
      :x="menuX"
      :y="menuY"
    />
  </div>
</template>

<style scoped></style>
