import { defineStore } from 'pinia';
import { generateCodeVerifier, OAuth2Client, OAuth2Fetch } from '@badgateway/oauth2-client';
import getFormattedTime from '@/utils/getFormattedTime.js';
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';

export const useAuthStore = defineStore(
  'authStore',
  () => {
    console.log('🔐', getFormattedTime(new Date()), 'authStoreSetup');

    const redirectUri = import.meta.env.VITE_BASE_URL;

    const oauth2Token = ref(null);
    const state = ref('');
    const codeVerifier = ref('');
    const code = ref('');

    function getClientSettings() {
      const authType = import.meta.env.VITE_AUTHENTICATION_TYPE;
      const server = import.meta.env.VITE_OAUTH2_BASE_URL;
      const clientId = import.meta.env.VITE_OAUTH2_CLIENT_ID;
      const discoveryEndpoint = import.meta.env.VITE_OAUTH2_DISCOVERY_ENDPOINT;
      const clientSecret = import.meta.env.VITE_OAUTH2_CLIENT_SECRET;

      const authTypeSettings = getAuthTypeSettings(authType);

      return {
        server,
        clientId,
        clientSecret: authType === 'keycloak' ? clientSecret : undefined,
        authorizationEndpoint: `${server}${authTypeSettings.authorizationEndpoint}`,
        tokenEndpoint: `${server}${authTypeSettings.tokenEndpoint}`,
        discoveryEndpoint,
      };
    }

    function getAuthTypeSettings(authType) {
      const authTypeSettings = {
        keycloak: {
          authorizationEndpoint: '/protocol/openid-connect/auth',
          tokenEndpoint: '/protocol/openid-connect/token',
        },
        saml: {
          authorizationEndpoint: '/oauth2/authorize',
          tokenEndpoint: '/oauth2/token',
        },
      };

      return authTypeSettings[authType];
    }

    const authClient = new OAuth2Client(getClientSettings());

    // const authClient = new OAuth2Client({
    //   // oAuth2Server,
    //   server: `https://vf-palladium-preprod-tf.auth.eu-central-1.amazoncognito.com`,
    //   // clientSecret,
    //   clientId: `48h3du1rs6lsec617vec7son2l`,
    //   authEndpoint: `/oauth2/authorize`,
    //   tokenEndpoint: `/oauth2/token`,
    //   discoveryEndpoint: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_GEGRRfPDN/.well-known/openid-configuration',
    //   // discoveryEndpoint,
    // });

    console.log('🔐', getFormattedTime(new Date()), 'authClient', authClient);

    const getLoginUri = () => {
      // https://vf-palladium-preprod-tf.auth.eu-central-1.amazoncognito.com/oauth2/authorize?response_type=code&identity_provider=VFAzureAD&client_id=7hjj1b3bfs11l53o4hb1fqued2&redirect_uri=http%3A%2F%2Flocalhost%3A3000
      const loginUri = new URL(import.meta.env.VITE_OAUTH2_BASE_URL);
      loginUri.pathname = '/oauth2/authorize';
      loginUri.searchParams.append('response_type', 'code');
      loginUri.searchParams.append('identity_provider', 'VFAzureAD');
      loginUri.searchParams.append('client_id', import.meta.env.VITE_OAUTH2_CLIENT_ID);
      loginUri.searchParams.append('redirect_uri', redirectUri);

      return loginUri.toString();
    };

    const getAuthorizationUri = async () => {
      codeVerifier.value = await generateCodeVerifier();
      state.value = uuidv4();

      return await authClient.authorizationCode.getAuthorizeUri({
        redirectUri: import.meta.env.VITE_BASE_URL,
        codeVerifier: codeVerifier.value,
        state: state.value,
        scope: ['openid', 'email', 'profile'],
      });
    };

    const logout = () => {
      if (import.meta.env.VITE_AUTHENTICATION_TYPE == 'saml') {
        const logoutUrl = new URL(import.meta.env.VITE_OAUTH2_BASE_URL);
        logoutUrl.pathname = '/logout';
        logoutUrl.searchParams.append('client_id', import.meta.env.VITE_OAUTH2_CLIENT_ID);
        logoutUrl.searchParams.append('logout_uri', redirectUri);
        window.location.href = logoutUrl.toString();
      } else  {
        const LogoutUrl = `${'/realms/{realm}/protocol/openid-connect/logout'}/logout?client_id=${import.meta.env.VITE_OAUTH2_CLIENT_ID}&logout_uri=${encodeURIComponent(import.meta.env.VITE_OAUTH2_BASE_URL)}`;
        window.location.href = LogoutUrl;
      }
    };

    const getTokensFromCodeRedirect = async (location) => {
      return await authClient.authorizationCode.getTokenFromCodeRedirect(location, {
        redirectUri,
        codeVerifier: codeVerifier.value,
      });
    };

    const setOauth2Token = (token) => {
      oauth2Token.value = token;
    };

    const getOauth2Token = () => {
      return oauth2Token.value;
    };

    const isTokenValid = () => {
      if (oauth2Token.value === null) return false;
      return Date.now() < oauth2Token.value.expiresAt;
    };

    /**
     * This function will fetch endpoints that require authentication,
     * providing the necessary headers and refreshing the token if necessary.
     *
     * @param {string} url - The URL to fetch
     * @param {object} options - The fetch options
     * @returns {Promise<Response>} - The fetch response
     *
     * @example
     * const response = fetchWrapper.fetch(
     * 'https://my-api',
     * {
     *   method: 'POST',
     *   body: 'Hello world'
     * });
     */
    let fetchWrapper = new OAuth2Fetch({
      client: authClient,
      getNewToken: async () => {
        console.log('🔑 Fetching new token');
        if (oauth2Token.value === null) {
          return authClient.authorizationCode.getToken({
            code: code.value,
            redirectUri: redirectUri.value,
          });
        }
        return oauth2Token.value;
      },
      onError: (err) => {
        console.error('🐞 Authentication error', { err });
      },
      storeToken: (token) => {
        setOauth2Token(token);
      },
      getStoredToken: () => {
        return getOauth2Token();
      },
      scheduleRefresh: true,
    });

    return {
      oauth2Token,
      authClient,
      state,
      codeVerifier,
      code,
      isTokenValid,
      getAuthorizationUri,
      getTokensFromCodeRedirect,
      setOauth2Token,
      getOauth2Token,
      getLoginUri,
      logout,
      fetchWrapper,
    };
  },
  {
    persist: true,
  },
);
