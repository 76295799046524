import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useChatStore } from '@/store/chatStore';
import { useAppStore } from '@/store/appStore.js';

export const useChatListStore = defineStore(
  'chatListStore',
  () => {
    const chats = ref([]);
    const chatStore = useChatStore();
    const appStore = useAppStore();

    function init() {
      if (chats.value.length === 0) {
        chatStore.createChat();
        addChat(chatStore.chat);
      }
    }

    function addChat(chat) {
      chats.value.push(chat);
    }

    const removeChat = (idToRemove) => {
      chats.value = chats.value.filter(chat => chat.id !== idToRemove);
      if (chats.value.length > 0) {
        const lastChat = chats.value[chats.value.length - 1];
        chatStore.chat = lastChat;
        appStore.currentChat = lastChat;
      } else {
        chatStore.createChat();
        addChat(chatStore.chat);
      }
    };

    function createNewChat() {
      const existingNewChat = chats.value.find(chat => chat.name === 'New Chat');
      if (existingNewChat) {
        chatStore.chat = existingNewChat;
        appStore.currentChat = existingNewChat;
      } else {
        chatStore.createChat();
        addChat(chatStore.chat);
        appStore.currentChat = chatStore.chat;
      }
    }

    return { init, chats, addChat, removeChat, createNewChat };
  },
  { persist: {
      enabled: true,
      strategies: [
        {
          key: 'chatList',
          storage: localStorage,
        },
      ],
    },
  },
);
