import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/store/userStore';
import { useAuthStore } from '@/store/authStore.js';
import { useAppStore } from '@/store/appStore.js';
import { version } from '@root/package.json';

import Home from '@/views/HomeView.vue';
import Login from '@/views/LoginView.vue';
import Faq from '@/views/FaqView.vue';

const isAuthenticationRequired = () => {
  console.log(
    'Is Authentication required:',
    import.meta.env.VITE_AUTHENTICATION_DISABLED !== 'true',
  );

  return import.meta.env.VITE_AUTHENTICATION_DISABLED !== 'true';
};

const getAuthenticationType = () => {
  console.log(
    '🔐 Authentication type:',
    import.meta.env.VITE_AUTHENTICATION_TYPE,
  );

  return import.meta.env.VITE_AUTHENTICATION_TYPE;
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: isAuthenticationRequired },
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: { requiresAuth: isAuthenticationRequired },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { guest: true },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log('🚦 Navigation guard', { to, from, next });
  const userVersion = localStorage.getItem('appVersion');
  if (userVersion !== version) {
    localStorage.clear();
    localStorage.setItem('appVersion', version);
  }
  next();
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticationDisabled = !isAuthenticationRequired();

  if (isAuthenticationDisabled) {
    const userStore = useUserStore();
    const appStore = useAppStore();
    userStore.setLoggedInUser({
      name: 'John Doe',
      email: 'john@doe.com',
    });
    await appStore.init();
  }
  next();
});

router.beforeEach(async (to, from, next) => {
  if (to.query.code) {
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const userStore = useUserStore();
    const currentURL = document.location;
    const oauth2Token = await authStore.getTokensFromCodeRedirect(currentURL);

    if (oauth2Token) {
      authStore.code = to.query.code;
      authStore.setOauth2Token(oauth2Token);

      const userInfo = await userStore.getUserInfo(oauth2Token.accessToken);
      console.log('user info:', userInfo);

      if (userInfo) {
        userStore.setLoggedInUser(userInfo);
      } else {
        console.error('User info not found');
      }

      await appStore.init();

      next({ name: 'home' });
      return;
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const isAuthRequired = to.matched.some(
    (record) => record.meta.requiresAuth && record.meta.requiresAuth(),
  );
  if (isAuthRequired) {
    if (authStore.isTokenValid()) {
      next();
      return;
    }
    next({ name: 'login' });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const guest = to.matched.some((record) => record.meta.guest);

  if (guest && authStore.isTokenValid()) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;
