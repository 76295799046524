<script setup lang="ts">
import {useOnboardingStore} from "@/store/onboardingStore";
import {computed} from "vue";
import TooltipContent from "@/components/common/TooltipContent.vue";
import {Dropdown} from 'floating-vue'
import {Placement} from "tippy.js";

interface OnboardingTooltipProps {
    tooltip_id: string;
    heading: string;
    content: string;
    placement?: Placement;
  }
  const props = defineProps<OnboardingTooltipProps>();

  const onboardingStore = useOnboardingStore();
  const tooltips = computed(() => onboardingStore.getTooltips());

  const shouldShowTooltip = computed(() => {
    let tooltip = tooltips.value.find((tooltip) => tooltip.id === props.tooltip_id);
    let tooltipShown = tooltip?.shown ?? false;

    let isOnboardingShown = onboardingStore.shouldShowOnboarding();
    if (isOnboardingShown) {
      return false;
    }

    if(!tooltipShown && tooltip.previous === undefined) {
      return true;
    }

    let previousTooltipShown = tooltips.value.find((previous) => previous.id === tooltip.previous)?.shown ?? false;
    return (tooltips.value[0].id === props.tooltip_id && tooltips.value[0].shown === false) || (!tooltipShown && previousTooltipShown);
  });

  function setTooltipShown() {
    onboardingStore.setTooltipShown(props.tooltip_id);
  }
</script>

<template>
  <Dropdown :shown="shouldShowTooltip" :triggers="[]" :auto-hide="false" :placement="props.placement" :distance="15">
    <slot></slot>
    <template #popper="{ hide }">
      <TooltipContent class="max-w-96 p-1" :heading=props.heading :content="props.content" :close-popup="() => { hide(); setTooltipShown(); }" />
    </template>
  </Dropdown>
</template>

