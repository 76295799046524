import { fetcher } from '@/utils/useFetcher.js';

const apiUrl = import.meta.env.VITE_API_URL;

export async function getPluginFromAPI() {
  const requestUrl = new URL(`/api/v1/plugin/`, apiUrl);

  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  }

  try {
    const response = await fetcher( requestUrl, requestOptions );
    return await response.json();
  } catch (error) {
    console.error('Error in the request using FetchWrapper():', error.message);
    throw error;
  }
}
