<template>
  <span ref="tooltipRef"></span>
  <div :id="props.tooltip_id" style="display: none">
    <slot />
  </div>
</template>

<script setup lang="ts">
  /**
   * This is the tooltip component. It uses tippy.js in the background.
   *  @example <tooltip tooltip_id="{Enter a unique identifier in order to use multiple tooltips inside the same component}" :options="{Override more properties from the tippy.js tooltip such as placement}">
   *              {Here you can add your custom content you want to show in your tooltip}
   *           </tooltip>
   * */
import tippy from 'tippy.js';
import type { Instance, Props } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import {ref, onMounted, onUpdated, onUnmounted} from "vue";

interface TooltipProps {
  text?: string;
  tooltip_id: string;
  options?: Partial<Props>;
}
const props = defineProps<TooltipProps>();
const tooltipRef = ref<HTMLElement>();

const tooltipInstance = ref<Instance>();

function destroyTooltip() {
  if (tooltipInstance.value) {
    tooltipInstance.value.destroy();
  }
}
function initTooltip() {
  destroyTooltip()
  const content = document.getElementById(props.tooltip_id);

  tooltipInstance.value = tippy(tooltipRef.value?.parentNode as HTMLElement, {
    content: props.text ?? content.innerHTML,
    allowHTML: true,
    theme: 'vf-assist',
    ...props.options,
  });
}

onMounted(initTooltip)
onUpdated(initTooltip)
onUnmounted(destroyTooltip)

</script>

<style scoped>
</style>
