<script setup>
  import { useI18n } from 'vue-i18n';
  import { ref } from 'vue';
  import { useUserStore } from '@/store/userStore';
  import { logout } from '@/auth/authUtils.js';
  import PagesMenu from './PagesMenu.vue';

  const { t } = useI18n();
  const menuVisible = ref(false);

  const toggleMenu = () => {
    menuVisible.value = !menuVisible.value;
  };

  const closeMenu = () => {
    menuVisible.value = false;
  };
  const userStore = useUserStore();

  const handleLogout = () => {
    logout();
  };
</script>

<template>
  <div class="flex flex-col relative dark">
    <button class="p-2 flex gap-2 flex-shrink items-center mt-2" @click="toggleMenu">
      <div class="avatar placeholder">
        <div class="bg-secondary text-neutral-content rounded-full w-8">
          <span v-if="userStore.loggedInUser">{{
            userStore.loggedInUser.email?.charAt(0).toUpperCase() || 'A'
          }}</span>
        </div>
      </div>
      <span
        class="text-white text-sm font-normal font-['VodafoneRg'] leading-[17.50px]"
        v-if="userStore.loggedInUser"
      >
        {{ userStore.loggedInUser.email || 'Anonymous User' }}
      </span>
    </button>
    <PagesMenu
      parentSource="sideBar"
      :menuVisible="menuVisible"
      @closeMenu="closeMenu"
      class="w-full bottom-full border border-neutral-600 invisible xl:visible"
    />
  </div>

  <button
    class="h-11 bg-opacity-5 bg-white hover:bg-primary-darker border rounded-lg border-neutral-600 font-['VodafoneRg'] text-zinc-100 xl:hidden"
    @click="handleLogout"
  >
    {{ t(`logout`) }}
  </button>
</template>
