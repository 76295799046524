<script setup>
const props = defineProps({
  fillColor: {
    type: String,
    default: '#3A3A3A',
  },
});
</script>

<template>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0723 3.73629C12.877 3.54103 12.5605 3.54103 12.3652 3.73629C12.1699 3.93155 12.1699 4.24814 12.3652 4.4434L20.0116 12.0898H2.46875C2.19261 12.0898 1.96875 12.3137 1.96875 12.5898C1.96875 12.866 2.19261 13.0898 2.46875 13.0898H20.0116L12.3652 20.7363C12.1699 20.9316 12.1699 21.2481 12.3652 21.4434C12.5605 21.6387 12.877 21.6387 13.0723 21.4434L21.5708 12.9449C21.5718 12.9439 21.5728 12.9429 21.5738 12.9419L21.9258 12.5898L21.5738 12.2378C21.5728 12.2368 21.5718 12.2358 21.5708 12.2348L13.0723 3.73629Z" :fill="props.fillColor"/>
    </svg>
</template>
