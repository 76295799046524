import { useChatStore, Status } from '@/store/chatStore';

export const handleDocumentSearchData = async (response, assistantMessageId) => {
  const chatStore = useChatStore();
  let fullResponseText = '';
  let responseChunks = [];
  let sourceDataList = [];
  let followupQuestions = null;
  let statusSet = false; // Variable to track if status has been set

  if (!response.body) {
    console.error('Response body is undefined');
    return;
  }

  const reader = response.body.getReader();

  const handleMessageData = async () => {
    const { done, value } = await reader.read();
    if (!done) {
      let textData = new TextDecoder().decode(value);

      if (textData.startsWith('event: response_chunk')) {
        const chunkData = textData.replace(/event: response_chunk|data: |\n|\r/g, '');
        responseChunks.push(chunkData);
        fullResponseText += chunkData;
        if (assistantMessageId) {
          chatStore.updateMessageContentById(assistantMessageId, fullResponseText);
        }
        if (!statusSet) {
          chatStore.setCurrentStatus(assistantMessageId, Status.SUCCESS);
          statusSet = true; // Set statusSet to true after the first update
        }
      } else if (textData.startsWith('event: source')) {
        let sourceDataRegex = /event: source\s+data:\s+({(?:[^{}]*{[^{}]*})*[^{}]*})/g;
        let match;
        while ((match = sourceDataRegex.exec(textData)) !== null) {
          let jsonData = match[1];

          // Note: The data block is treated as a character string, as the JSON format can be different for different plugins.
          let scoreMatch = /'score':\s*([0-9.]+)/.exec(jsonData);
          let titleMatch = /'title':\s*'([^']+)'/g.exec(jsonData);
          let sourceUrlMatch = /'source_url':\s*'([^']+)'/g.exec(jsonData);

          if (scoreMatch && titleMatch && sourceUrlMatch) {
            let score = parseFloat(scoreMatch[1]);
            let title = titleMatch[1];
            let sourceUrl = sourceUrlMatch[1];

            let sourceData = { score, title, sourceUrl };
            sourceDataList.push(sourceData);
          } else {
            console.error('Error extracting the data from the string.');
          }
        }
      } else if (textData.startsWith('event: followup_questions')) {
        let jsonData = textData.split("data: ")[1];
        if (jsonData) {
          // Replace single inverted commas with double inverted commas
          jsonData = jsonData.replace(/'/g, '"');
          try {
            followupQuestions = JSON.parse(jsonData);
          } catch (error) {
            console.error('Error parsing the follow-up questions:', error);
            return; // Return immediately if parsing fails
          }
        }
      } else if (textData.startsWith('event: user_exit_flow')) {
        chatStore.updateShowUserExitFlow(true);
      }
      await handleMessageData();
    }
  };

  try {
    await handleMessageData();

    // Process source data
    for (let sourceData of sourceDataList) {
      chatStore.addSourcesToMessageById(assistantMessageId, sourceData);
    }

    // Process followup_questions data
    if (followupQuestions) {
      chatStore.updateFollowupQuestions(followupQuestions);
    }
  } catch (error) {
    console.error('Error handling message data:', error);
  }
};
