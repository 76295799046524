import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useChatListStore } from '@/store/chatListStore';
import { useAppStore } from '@/store/appStore';
import { getSummary } from '@/services/chatSummary';

/**
 * Represents a chat object.
 * @typedef {Object} Chat
 * @property {string} id - The ID of the chat.
 * @property {string} createdAt - The creation timestamp of the chat.
 * @property {string} lastMessageAt - The timestamp of the last message of the chat.
 * @property {string} name - The name of the chat.
 * @property {Message[]} messages - The array of messages in the chat.
 * @property {Followup[]} followups - Followup Question of the chat.
 * @property {Boolean} pinned - Marks a Chat as pinned to the sidebar.
 * @property {Boolean} showUserExitFlow - Marks a Chat as having a user exit flow.
 */

/**
 * Represents a followup object.
 * @typedef {Object} Followup
 * @property {string} question - The question of the chat.
 */

/**
 * Represents a message object.
 * @typedef {Object} Message
 * @property {number} id - The ID of the message.
 * @property {string} timestamp - The timestamp of the message.
 * @property {Role} role - The role of the message, either 'user' or 'assistant'.
 * @property {string} content - The content of the message.
 * @property {Status} status - The status of the message, either 'loading', 'finished', or 'interrupted'.
 * @property {string} plugin - The plugin of the message.
 */

export const useChatStore = defineStore(
  'chatStore',
  () => {
    const chat = ref({});
    const chatListStore = useChatListStore();

    const createChat = (name = 'New Chat') => {
      const uuid = self.crypto.randomUUID();
      chat.value = {
        id: uuid,
        createdAt: new Date().toISOString(),
        lastMessageAt: new Date().toISOString(),
        name: name,
        messages: [],
        followups: [],
        pinned: false,
        showUserExitFlow: false,
      };
    };

    const addMessage = async (message) => {
      chat.value.messages.push(message);
      chat.value.lastMessageAt = new Date().toISOString();
      if (chat.value.name === 'New Chat') {
        // Call getSummary after adding the message
        const appStore = useAppStore();
        const chatSummary = await getSummary(appStore.currentLanguage.name.toLowerCase());
        changeName(chatSummary);
      }
      updateToChatListStore();
    };

    const updateToChatListStore = () => {
      const index = chatListStore.chats.findIndex((chats) => chats.id === chat.value.id);
      if (index !== -1) {
        chatListStore.chats[index] = chat.value;
      }
    };

    const updateFollowupQuestions = (questions) => {
      chat.value.followups = questions;
    };

    const removeMessage = (message) => {
      chat.value.messages = chat.value.messages.filter((m) => m.id !== message.id);
    };

    const updateMessageContentById = (messageId, content) => {
      const message = chat.value.messages.find((m) => m.id === messageId);
      if (message) {
        message.content = content;
      }
    };

    const addSourcesToMessageById = (messageId, sources) => {
      const message = chat.value.messages.find((m) => m.id === messageId);
      if (message) {
        if (!message.sources) {
          message.sources = [];
        }
        message.sources = message.sources.concat(sources);
      }
    };


    const setCurrentStatus = (messageId, newStatus) => {
      const message = chat.value.messages.find((m) => m.id === messageId);
      if (message) {
        message.status = newStatus;
      }
    };

    const changeName = (name) => {
      chat.value.name = name;
    };

    const pinChat = (id, maxPinnedChats) => {
      let chat = chatListStore.chats.find((chat) => chat.id === id);
      if(chatListStore.chats.filter((chat) => chat.pinned === true).length >= maxPinnedChats && chat.pinned === false) return;
      chat.pinned = !chat.pinned;
      updateToChatListStore();
    }

    const updateShowUserExitFlow = (showUserExitFlow) => {
      chat.value.showUserExitFlow = showUserExitFlow;
    }

    return {
      chat,
      createChat,
      addMessage,
      removeMessage,
      changeName,
      updateMessageContentById,
      updateFollowupQuestions,
      addSourcesToMessageById,
      setCurrentStatus,
      pinChat,
      updateShowUserExitFlow,
    };
  },
  { persist: true },
);

export const createMessage = ({ role, content = '', status, plugin }) => {
  const message = {
    id: self.crypto.randomUUID(),
    role,
    content,
    status,
    plugin,
  };

  return message;
};

export const Role = {
  USER: 'user',
  ASSISTANT: 'assistant',
};

export const Status = {
  LOADING: 'loading',// Request is sent, loading bar is displayed
  SUCCESS: 'success', // Successful response received from the API
  FINISHED: 'finished', // All data has been loaded successfully
  ERROR: 'error',// Error with the API request
};
