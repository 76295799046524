<script setup>
  import { ref, onMounted } from 'vue';

  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const props = defineProps(['onSubmit']);
  const form = ref(null);
  const chatInput = ref(null);
  const isChatInputEmpty = ref(true);
  const isLoading = ref(false);
  const inputHeight = '1.5rem';

  onMounted(() => {
    chatInput.value.style.height = inputHeight;
  });

  const handleOnSubmit = () => {
    const formData = new FormData(form.value);
    const values = Object.fromEntries(formData.entries());

    isLoading.value = true;
    props.onSubmit(values);
    resetInput();
    chatInput.value.focus();
    isLoading.value = false;
  };

  const handleOnInput = () => {
    isChatInputEmpty.value = chatInput.value.value.length === 0;
    resize();
  };

  const resetInput = () => {
    chatInput.value.value = '';
    chatInput.value.style.height = inputHeight;
    isChatInputEmpty.value = true;
  };

  const resize = () => {
    const input = chatInput.value;
    input.style.height = inputHeight;
    input.style.height = input.scrollHeight + 'px';
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleOnSubmit();
      resize();
    } else if (event.key === 'Enter' && event.shiftKey) {
      insertNewLine();
    }
  };

  const insertNewLine = () => {
    const cursorPos = chatInput.value.selectionStart;
    const textBeforeCursor = chatInput.value.value.substring(0, cursorPos);
    const textAfterCursor = chatInput.value.value.substring(cursorPos);

    chatInput.value.value = textBeforeCursor + '\n' + textAfterCursor;
    chatInput.value.setSelectionRange(cursorPos + 1, cursorPos + 1);
    resize();
  };
</script>

<template>
  <form
    ref="form"
    @submit.prevent="handleOnSubmit"
    class="input-form flex w-full bg-white py-2 px-4 items-center gap-4 self-stretch rounded-lg border border-monochrome-250 shadow-md focus-within:outline-2 focus-within:outline-blue-700 focus-within:outline"
  >
    <label for="chatInput" class="sr-only">Chat Input</label>
    <textarea
      id="chatInput"
      class="chat-input font-VFRegular w-full flex-grow border-none outline-none resize-none disabled:bg-white max-h-32"
      ref="chatInput"
      name="chatInput"
      :placeholder="t(`placeholder.chatinput`)"
      @input="handleOnInput"
      @keydown.enter.prevent="handleEnterKey"
      :disabled="isLoading"
    ></textarea>
    <button
      name="submitButton"
      class="text-white flex items-center justify-center rounded-lg w-9 h-9 bg-brand-primary hover:bg-primary-darker disabled:bg-white disabled:text-monochrome-600 self-end"
      type="submit"
      :disabled="isChatInputEmpty"
    >
      <svg width="18" height="18" viewBox="0 0 24 24">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.73073 4.0348C2.53592 3.95778 2.31375 4.01036 2.17413 4.16653C2.03451 4.3227 2.00704 4.54936 2.10532 4.73436L5.96508 11.9998L2.10532 19.2652C2.00704 19.4502 2.03451 19.6769 2.17413 19.833C2.31375 19.9892 2.53592 20.0418 2.73073 19.9648L21.6995 12.4648C21.8903 12.3893 22.0156 12.205 22.0156 11.9998C22.0156 11.7946 21.8903 11.6102 21.6995 11.5348L2.73073 4.0348ZM6.83193 11.5L3.62519 5.46379L20.1558 11.9998L3.62519 18.5358L6.83169 12.5H10.5469C10.823 12.5 11.0469 12.2761 11.0469 12C11.0469 11.7239 10.823 11.5 10.5469 11.5H6.83193Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </form>
</template>

<style scoped></style>
