<script setup>
  import { ref, computed, watch } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useAppStore } from '@/store/appStore';

  const props = defineProps(['sources']);
  const { currentLanguage } = storeToRefs(useAppStore());
  const sourcesHeader = ref('');

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  watch(
    currentLanguage,
    (newValue) => {
      sourcesHeader.value = newValue.name.toLowerCase() === 'english' ? 'Sources:' : 'Quellen:';
    },
    { immediate: true },
  );

  const getSourceUrlByTitle = (title) => {
    const source = props.sources.find((source) => source.title === title);
    return source ? source.sourceUrl : '';
  };

  const uniqueTitles = computed(() => {
    const titles = props.sources.map((source) => source.title);
    return [...new Set(titles)];
  });
</script>

<template>
  <span class="text-sm tracking-tight font-['VodafoneRg']">{{ sourcesHeader }}</span>
  <ol>
    <li v-for="(uniqueTitle, uniqueIndex) in uniqueTitles" :key="uniqueIndex" class="mb-1">
      <button
        class="inline-flex w-full h-full p-2 text-left bg-white rounded-lg border border-neutral-200 items-center hover:bg-opacity-30 hover:bg-gray-500"
        type="button"
        @click="openLink(getSourceUrlByTitle(uniqueTitle))"
      >
        <span class="text-sm tracking-tight font-['VodafoneRg']">{{ uniqueTitle }}</span>
      </button>
    </li>
  </ol>
</template>
