<template>
  <div class="h-6 w-6 flex justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 11C4.44802 11 4 11.448 4 12C4 12.552 4.44802 13 5 13C5.55198 13 6 12.552 6 12C6 11.448 5.55198 11 5 11ZM3 12C3 10.8957 3.89573 10 5 10C6.10427 10 7 10.8957 7 12C7 13.1043 6.10427 14 5 14C3.89573 14 3 13.1043 3 12ZM12 11C11.448 11 11 11.448 11 12C11 12.552 11.448 13 12 13C12.552 13 13 12.552 13 12C13 11.448 12.552 11 12 11ZM10 12C10 10.8957 10.8957 10 12 10C13.1043 10 14 10.8957 14 12C14 13.1043 13.1043 14 12 14C10.8957 14 10 13.1043 10 12ZM18 12C18 11.448 18.448 11 19 11C19.552 11 20 11.448 20 12C20 12.552 19.552 13 19 13C18.448 13 18 12.552 18 12ZM19 10C17.8957 10 17 10.8957 17 12C17 13.1043 17.8957 14 19 14C20.1043 14 21 13.1043 21 12C21 10.8957 20.1043 10 19 10Z"
        fill="white"
      />
    </svg>
  </div>
</template>
