<script setup>
  import { FeatureToggleComponent as feature } from 'vue-feature-toggle';
  import featureToggles from '../../features.json';
  import { storeToRefs } from 'pinia';
  import { ref } from 'vue';
  import { sendChatMessage } from '@/services/chatFeatures';
  import { useAppStore } from '@/store/appStore';
  import { useChatStore, Role, Status, createMessage } from '@/store/chatStore';
  import AppContainer from '@/components/common/AppContainer.vue';
  import ChatFollowupContainer from '@/components/chat/ChatFollowupContainer.vue';
  import ChatInput from '@/components/chat/ChatInput.vue';
  import ChatInputContainer from '@/components/chat/ChatInputContainer.vue';
  import ChatListContainer from '@/components/chat/ChatListContainer.vue';
  import ChatMessages from '@/components/chat/ChatMessages.vue';
  import ChatMessagesContainer from '@/components/chat/ChatMessagesContainer.vue';
  import CreateNewChat from '@/components/common/CreateNewChat.vue';
  import FootContent from '@/components/common/FootContent.vue';
  import LanguageSelector from '@/components/common/LanguageSelector.vue';
  import LoggedInUser from '@/components/common/LoggedInUser.vue';
  import MainContent from '@/components/common/MainContent.vue';
  import NavContent from '@/components/common/NavContent.vue';
  import PluginSelector from '@/components/common/PluginSelector.vue';
  import SideBar from '@/components/common/SideBar.vue';
  import MenuToggleButton from '@/components/common/MenuToggleButton.vue';
  import PagesSelector from '@/components/common/PagesSelector.vue';
  import Welcome from '@/components/home/WelcomeView.vue';
  import OverlayContainer from '@/components/common/OverlayContainer.vue';
  import OnboardingTooltip from "@/components/common/OnboardingTooltip.vue";
  import { useI18n } from 'vue-i18n';
  import OnboardingPopup from "@/components/common/OnboardingPopup.vue";
  import {useOnboardingStore} from "@/store/onboardingStore.js";
  const { t } = useI18n();

  feature.requiredVisibility((feature) => {
    return featureToggles[feature.name] === true;
  });

  const appStore = useAppStore();
  const chatStore = useChatStore();
  const onboardingStore = useOnboardingStore();
  const { currentPlugin } = storeToRefs(useAppStore());

  async function handleOnSubmit({ chatInput }) {
    const message = createMessage({
      role: Role.USER,
      content: chatInput,
      status: Status.FINISHED,
      plugin: appStore.currentPlugin.name,
    });
    chatStore.addMessage(message);

    try {
      const success = await sendChatMessage(chatInput);
      if (success) {
        console.log('Message sent successfully.');
      } else {
        console.log('Message could not be sent.');
      }
    } catch (error) {
      console.error('Error sending the message:', error);
    }
  }
  const sidebarIsVisible = ref(true);
  //userPreference is a flag to determine whether the user closes the sidebar on its own or by the auto-closing functionality
  const userPreference = ref(false);

  const toggleSidebar = () => {
    //Set userPreference to true means that: the user clicks on closing button by its own
    userPreference.value = true;
    sidebarIsVisible.value = !sidebarIsVisible.value;
  };

  const closeSidebar = () => {
    userPreference.value = false;
    sidebarIsVisible.value = false;
  };

  const openSidebar = () => {
    userPreference.value = false;
    sidebarIsVisible.value = true;
  };
</script>

<template>
  <AppContainer class="flex flex-row-reverse h-dvh">
    <OverlayContainer v-if="sidebarIsVisible"></OverlayContainer>
    <MainContent class="flex-1 basis-full flex flex-col w-full">
      <NavContent>
        <template v-slot:navSectionLeft>
          <div
            :class="{
              'slide-in': sidebarIsVisible,
              'slide-out': !sidebarIsVisible,
            }"
            class="flex gap-x-6 flex-1"
          >
            <MenuToggleButton
              @close="closeSidebar"
              @open="openSidebar"
              :handle-on-click="toggleSidebar"
              :sidebar-visible="sidebarIsVisible"
              :user-preference="userPreference"
            />
            <CreateNewChat class="md:hidden" />
          </div>
        </template>

        <template v-slot:plugin>
          <PluginSelector :selected-plugin="currentPlugin" />
        </template>

        <template v-slot:navSectionRight>
          <div class="hidden md:flex flex-1 justify-end">
            <CreateNewChat />
          </div>
        </template>
      </NavContent>
      <ChatMessagesContainer>
        <template v-slot:messages>
          <ChatMessages v-if="chatStore?.chat?.messages?.length > 0" />
          <Welcome v-else />
        </template>
      </ChatMessagesContainer>
      <FootContent>
        <template v-slot:userInput>
          <ChatInputContainer>
            <ChatFollowupContainer v-if="chatStore?.chat?.followups?.length > 0" />
            <ChatInput :on-submit="handleOnSubmit" />
          </ChatInputContainer>
        </template>
        <template v-slot:pagesSelector>
          <PagesSelector />
        </template>
      </FootContent>
    </MainContent>
    <div
      class="h-full relative left-0 z-30 lg:absolute"
      :class="{
        'is-open': sidebarIsVisible,
        'is-closed': !sidebarIsVisible,
      }"
    >
      <SideBar class="absolute right-0 w-80 h-full pt-10 px-4 pb-4">
        <template v-slot:header>
          <MenuToggleButton
            @close="closeSidebar"
            @open="openSidebar"
            :handle-on-click="toggleSidebar"
            :sidebar-visible="sidebarIsVisible"
            :user-preference="userPreference"
          />
          <OnboardingTooltip tooltip_id="newChatHintHeading"
                             :heading="t(`newChatHintHeading`)"
                             :content="t('newChatHintContent')"
                             placement="right">
            <CreateNewChat />
          </OnboardingTooltip>
        </template>
        <template v-slot:content><ChatListContainer /></template>
        <template v-slot:footer>
          <LanguageSelector />
          <LoggedInUser />
        </template>
      </SideBar>
    </div>

    <Teleport to="body">
      <OnboardingPopup :show="onboardingStore.shouldShowOnboarding()" @close="onboardingStore.setOnboardingShown(true);" />
    </Teleport>
  </AppContainer>
</template>

<style>
  .is-open,
  .is-closed {
    transition: all 0.8s ease-out;
    transform: translate3d(0, 0, 0);
  }

  .is-open {
    flex: 0 0 320px;
    width: 320px;
  }

  .is-closed {
    flex: 0 0 0;
    width: 0;
  }

  .slide-in,
  .slide-out {
    transition: all 400ms cubic-bezier(0.42, 0, 0.58, 1);
  }

  .slide-in {
    opacity: 0;
    visibility: hidden;
  }

  .slide-out {
    opacity: 1;
  }

  main {
    container-type: inline-size;
  }
</style>
