<script setup>
  import { FeatureToggleComponent as feature } from 'vue-feature-toggle';
  import { defineEmits, defineProps } from 'vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const { actions, x, y } = defineProps(['actions', 'x', 'y']);
  const emit = defineEmits(['action-clicked']);

  const emitAction = (action) => {
    emit('action-clicked', action);
  };

  const determineTextColor = (label) => {
    if (label === t('deleteChat')) {
      return 'text-error';
    } else {
      return 'text-text-secondary';
    }
  };
</script>

<template>
  <div
    class="flex flex-col absolute w-36 p-3 justify-center items-start rounded-lg z-50 text-base font-normal font-['VodafoneRg'] leading-none bg-white shadow-shadow-medium cursor-pointer"
    :style="{ top: y + 'px', left: x + 'px' }"
  >
    <feature v-for="action in actions" :key="action.action" :name="action.action">
      <button class="w-full px-4 py-3 hover:bg-monochrome-200" @click="emitAction(action.action)">
        <span :class="determineTextColor(action.label)">{{ action.label }}</span>
      </button>
    </feature>
  </div>
</template>

<style scoped></style>
