import { useAuthStore } from '@/store/authStore.js';
import { useUserStore } from '@/store/userStore';
import router from '@/router/router.js';

const isAuthenticationRequired = import.meta.env.VITE_AUTHENTICATION_DISABLED;

export async function login() {
  const authStore = useAuthStore();

  if (authStore.isTokenValid()) {
    await router.push({ name: 'home' });
    return;
  }

  window.location.href = await authStore.getAuthorizationUri();
}

export async function logout() {
  const authStore = useAuthStore();
  const userStore = useUserStore();

  authStore.logout();

  if (isAuthenticationRequired === 'false') {
    window.localStorage.removeItem('authStore');
    window.localStorage.removeItem('userStore');
    window.localStorage.removeItem('pluginStore');
  }

  authStore.$dispose();
  userStore.$dispose();
}
