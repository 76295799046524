<template>
  <div class="flex items-center">
    <svg
      class="animate-spin w-5 h-5 flex-shrink-0 self-center"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M0 10C0 8.02219 0.586491 6.08879 1.6853 4.4443C2.78412 2.79981 4.3459 1.51808 6.17317 0.761205C8.00043 0.00432834 10.0111 -0.193705 11.9509 0.192147C13.8907 0.577999 15.6725 1.53041 17.0711 2.92893C18.4696 4.32746 19.422 6.10929 19.8079 8.0491C20.1937 9.98891 19.9957 11.9996 19.2388 13.8268C18.4819 15.6541 17.2002 17.2159 15.5557 18.3147C13.9112 19.4135 11.9778 20 10 20V15C10.9889 15 11.9556 14.7068 12.7779 14.1573C13.6001 13.6079 14.241 12.827 14.6194 11.9134C14.9978 10.9998 15.0969 9.99445 14.9039 9.02455C14.711 8.05464 14.2348 7.16373 13.5355 6.46447C12.8363 5.7652 11.9454 5.289 10.9755 5.09607C10.0055 4.90315 9.00021 5.00216 8.08658 5.3806C7.17295 5.75904 6.39206 6.3999 5.84265 7.22215C5.29325 8.04439 5 9.01109 5 10H0Z"
        fill="url(#paint0_angular_639_2590)"
      />
      <circle cx="2.5" cy="2.5" r="2.5" transform="matrix(-1 0 0 1 12.5 15)" fill="#E60000" />
      <defs>
        <radialGradient
          id="paint0_angular_639_2590"
          cx="0"
          cy="0"
          r="2"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10 10) rotate(90) scale(10)"
        >
          <stop offset="0" stop-color="#E60000" />
          <stop offset="0.25053" stop-color="#E60000" stop-opacity="0.921241" />
          <stop offset="0.75" stop-color="#E60000" stop-opacity="0.01" />
        </radialGradient>
      </defs>
    </svg>
  </div>
</template>
