<template>
  <span class="h-4 w-4 flex justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_3928_15971)">
        <path
          d="M13.3187 5.9387C12.1662 3.01536 8.84908 1.54598 5.92658 2.69765C3.00325 3.85015 1.56825 7.15348 2.71992 10.076C2.96334 10.6937 3.31306 11.264 3.75325 11.761C3.42242 12.9652 2.53492 13.3727 2.49742 13.3902C2.42608 13.4209 2.36988 13.4788 2.34113 13.5509C2.31238 13.6231 2.31345 13.7038 2.34408 13.7752C2.36499 13.824 2.39887 13.8663 2.44205 13.8972C2.48524 13.9282 2.53609 13.9468 2.58908 13.951C2.92575 13.9793 3.26575 14.0027 3.59992 14.0027C4.45158 14.0027 5.27158 13.856 5.92575 13.281C8.84742 14.4343 12.1508 13.001 13.3041 10.0793C13.8341 8.73782 13.8341 7.24498 13.3041 5.90348L13.3187 5.9387Z"
          stroke="#333333"
          stroke-width="1.33"
          stroke-miterlimit="10"
        />
        <path
          d="M6.33203 5.79387C6.33203 5.79387 6.5587 4.57637 8.0087 4.58304C9.4587 4.5897 9.94786 6.36804 9.1862 7.14554C8.9086 7.42738 8.62859 7.70683 8.3462 7.98387C8.24021 8.08799 8.15612 8.21226 8.09887 8.34936C8.04163 8.48646 8.01239 8.63363 8.01286 8.7822V9.42637"
          stroke="#333333"
          stroke-width="1.33"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.99957 11.3614C8.1223 11.3614 8.22179 11.2619 8.22179 11.1392C8.22179 11.0165 8.1223 10.917 7.99957 10.917C7.87684 10.917 7.77734 11.0165 7.77734 11.1392C7.77734 11.2619 7.87684 11.3614 7.99957 11.3614Z"
          fill="#333333"
          stroke="#333333"
          stroke-width="0.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3928_15971">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
</template>
