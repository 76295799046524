<script setup>
  import SvgNewChat from '@/components/common/svg/svgNewChat.vue';
  import { useChatListStore } from '@/store/chatListStore.js';

  const chatListStore = useChatListStore();

  const handleCreateNewChat = () => {
    chatListStore.createNewChat();
  };
</script>

<template>
  <div class="rounded-lg shadow-md bg-white">
    <button
      class="w-10 h-10 flex justify-center items-center text-white font-bold rounded-lg"
      @click="handleCreateNewChat"
      id="createNewChatButton"
    >
      <SvgNewChat :fillColor="'#0D0D0D'" />
    </button>
  </div>
</template>
