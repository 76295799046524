<script setup>
  import { watch, ref } from 'vue';
  import { useAppStore } from '@/store/appStore';
  import { storeToRefs } from 'pinia';

  import SvgCopyClick from '../common/svg/svgCopyClick.vue';
  import SvgCopyCheck from '../common/svg/svgCopyCheck.vue';

  const clicked = ref(false);
  const copyTipContent = ref('');

  const props = defineProps({
    textCopy: String,
    onClick: Function,
  });

  const { currentLanguage } = storeToRefs(useAppStore());

  watch(
    currentLanguage,
    (selectedLanguage) => {
      copyTipContent.value = selectedLanguage.name === 'english' ? 'Copy' : 'Kopieren';
    },
    { immediate: true },
  );

  const copy = () => {
    navigator.clipboard.writeText(props.textToCopy);
    clicked.value = true;
    setTimeout(() => {
      clicked.value = false;
    }, 2000);
    if (typeof props.onClick === 'function') props.onClick();
  };
</script>

<template>
  <button
    class="p-2 rounded-lg hover:bg-monochrome-200"
    @click="copy"
    v-tippy="{
      content: copyTipContent,
      placement: 'bottom',
      arrow: true,
      animation: 'fade',
      theme: 'light',
    }"
  >
    <Transition name="confirm">
      <SvgCopyClick v-if="!clicked" />
      <SvgCopyCheck v-else-if="clicked" />
    </Transition>
  </button>
</template>

<style scoped></style>
