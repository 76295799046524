import { useAppStore } from '@/store/appStore';
import { fetcher } from '@/utils/useFetcher.js';

const apiUrl = import.meta.env.VITE_API_URL;

export async function sendFeedback (language,chat_history, rating, userText, sources ) {

  const appStore = useAppStore();
  const plugin = appStore.currentPlugin.name

  const requestUrl = new URL('/api/v1/feedback?', apiUrl);
  requestUrl.searchParams.set('language', language);
  requestUrl.searchParams.set('plugin', plugin);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify({
      "rating":rating,
      "rating_explainer": userText,
        "chat_history": chat_history,
        "sources": {sources}
      })
  };

  try {
    const response = await fetcher(requestUrl, requestOptions);
    let fullResponseText = '';
    const reader = response.body.getReader();

    const handleMessageData = async () => {
      const { done, value } = await reader.read();
      if (!done) {
        const textData = new TextDecoder().decode(value);
        const cleanedText = textData.replace(/(data: |\n)/g, '');

        fullResponseText += cleanedText;

        await handleMessageData();
      }
    };

    await handleMessageData();

    return fullResponseText;

  } catch (error) {
    console.error('Error:', error);
    return false
  }
}


