<script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import SvgMenu from './svg/svgMenu.vue';

  const props = defineProps({
    handleOnClick: Function,
    sidebarVisible: Boolean,
    userPreference: Boolean,
  });

  const emit = defineEmits(['close', 'open']);
  const prevWidth = ref(window.innerWidth);

  onMounted(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  const handleResize = () => {
    const currentWidth = window.innerWidth;

    const isSizeIncreasing = currentWidth > prevWidth.value;
    const isSizeDecreasing = currentWidth < prevWidth.value;

    const shouldSidebarClose =
      window.innerWidth >= 950 && window.innerWidth <= 1050 && props.sidebarVisible;
    const shouldSidebarOpen =
      window.innerWidth > 950 && !props.userPreference && !props.sidebarVisible;

    if (isSizeIncreasing && shouldSidebarOpen) {
      emit('open');
    } else if (isSizeDecreasing && shouldSidebarClose) {
      emit('close');
    }
    prevWidth.value = currentWidth;
  };
</script>

<template>
  <button
    type="button"
    @click="props.handleOnClick"
    class="bg-white h-10 w-10 flex items-center justify-center rounded-lg shadow-md"
  >
    <SvgMenu color="#0D0D0D"></SvgMenu>
  </button>
</template>
