<script setup>
  import { watch, ref } from 'vue';
  import { useAppStore } from '@/store/appStore';
  import { storeToRefs } from 'pinia';
  import LanguagesMenu from './LanguagesMenu.vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  // const props = defineProps(['onChange']);
  const { currentLanguage } = storeToRefs(useAppStore());

  const menuVisible = ref(false);
  const { locale } = useI18n();

  const toggleMenu = () => {
    menuVisible.value = !menuVisible.value;
  };

  const closeMenu = () => {
    menuVisible.value = false;
  };

  watch(
    currentLanguage,
    (selectedLanguage) => {
      locale.value = selectedLanguage.id;
    },
    { immediate: true },
  );
</script>

<template>
  <div class="relative h-auto pt-4 items-center inline-flex flex-col-reverse">
    <button
      @click="toggleMenu"
      class="w-full flex justify-between py-3 px-4 bg-white bg-opacity-5 rounded-lg border border-neutral-600"
      type="button"
      id="menuButton"
      aria-haspopup="true"
    >
      <span class="text-zinc-100 text-base font-normal font-['VodafoneRg'] cursor-pointer">
        {{ t(`language`) }}
      </span>

      <span class="bg-transparent text-neutral-400 font-['VodafoneRg'] text-sm">
        {{ currentLanguage.name === 'german' ? t(`german`) : t(`english`) }}
      </span>
    </button>

    <LanguagesMenu :menuVisible="menuVisible" @closeMenu="closeMenu" />
  </div>
</template>
