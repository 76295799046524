<script setup>
  import ChatBubble from '@/components/chat/ChatBubble.vue';
  import { useChatStore } from '@/store/chatStore';
  import { useAppStore } from '@/store/appStore';
  import { storeToRefs } from 'pinia';
  import { watch, ref } from 'vue';

  const { currentLanguage } = storeToRefs(useAppStore());
  const infoLabel = ref('');
  const chatStore = useChatStore();

  watch(
    currentLanguage,
    (selectedLanguage) => {
      infoLabel.value = selectedLanguage.name === 'english' ? 'Today' : 'Heute';
    },
    { immediate: true },
  );
</script>

<template>
  <div class="w-1/2 max-w-190 min-w-172 md:w-full md:min-w-0 md:px-5">
    <div class="text-center">
      <span class="rounded-[2.5rem] px-8 py-1 bg-white font-VFRegular p-2 font-bold">
        {{ infoLabel }}
      </span>
    </div>
    <ol class="flex flex-col gap-6 mt-7">
      <li v-for="(message, index) in chatStore.chat.messages" :key="index">
        <ChatBubble
          :messageRole="message.role"
          :text="message.content"
          :sources="message.sources"
          :status="message.status"
          :index="index"
        />
      </li>
    </ol>
  </div>
</template>
