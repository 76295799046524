<script setup>
  import { defineProps } from 'vue';

  const props = defineProps({
    svgComponent: {
      type: Object,
      required: true,
    },
    title: String,
    text: Array,
  });
</script>

<template>
  <div
    class="items-start bg-monochrome-100 border-solid border rounded-corner-m flex flex-col gap-spacing-m p-spacing-l relative w-[252px]"
  >
    <div class="h-[24px] w-[24px] relative">
      <div class="h-[18px] w-[20px] absolute left-[2px] top-[3px]">
        <!-- Rendern der SVG-Komponente -->
        <component :is="props.svgComponent" />
      </div>
    </div>
    <div class="flex items-start flex-col gap-[8px] relative">
      <div
        class="self-stretch text-text-primary font-VFBold text-[16px] font-bold tracking-normal leading-none -mt-[1px] relative"
      >
        {{ title }}
      </div>
    </div>
    <div class="h-[177px] w-[204px]">
      <p
        class="text-[#0d0d0d] font-VFRegular text-[16px] font-normal left-0 tracking-normal leading-[16px] top-0 w-[204px]"
      >
        <span class="leading-[24px]" v-for="(item, index) in props.text" :key="index">
          {{ item }}<br v-if="index < props.text.length - 1" /><br
            v-if="index < props.text.length - 1"
          />
        </span>
      </p>
    </div>
  </div>
</template>

<style scoped>
  .line-break {
    display: block;
    content: '';
    height: 13px;
  }
</style>
