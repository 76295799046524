import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useChatListStore } from '@/store/chatListStore';
import { usePluginStore } from '@/store/pluginStore';

export const useAppStore = defineStore(
  'appStore',
  () => {
    const availableLanguages = ref([]);
    const currentLanguage = ref({});
    const currentChat = ref(null);
    const currentPlugin = ref(null);
    const pluginStore = usePluginStore();
    const chatListStore = useChatListStore();

    const init = async () => {
      await pluginStore.init();
      await chatListStore.init();
      if (currentChat.value === null) {
        // currentChat.value = chatListStore.chats.slice(-1);
        currentChat.value = chatListStore.chats[chatListStore.chats.length - 1];
      }
      if (currentPlugin.value === null) {
        currentPlugin.value = pluginStore.filteredPlugins[0];
      }

      loadAvailableLanguages();
    };

    const loadAvailableLanguages = () => {
      if (currentPlugin.value) {
        const pluginLanguageType = Array.from(new Set(currentPlugin.value.languages.map(language => language.type)));
        const typeToIdMapping = {
          'english': 'en',
          'german': 'de'
        };

        availableLanguages.value = pluginLanguageType.map(type => ({
          id: typeToIdMapping[type],
          name: type
        }));

        if (availableLanguages.value.length > 0) {
          currentLanguage.value = availableLanguages.value[0];
        }
      }
    };

    watch(currentPlugin, () => {
      loadAvailableLanguages();
    });

    return {
      availableLanguages,
      currentLanguage,
      currentChat,
      currentPlugin,
      init,
      loadAvailableLanguages
    };
  },
  { persist: true }
);
