<script setup>
  import SvgSystemSMS from '@/components/common/svg/svgSystemSMS.vue';
  import FaqHeaderSection from '@/components/faq/FaqHeaderSection.vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();
</script>
<template>
  <div class="flex justify-center font-['VodafoneRg'] grow">
    <div class="w-190 mx-10 md:mx-5 flex-col justify-between inline-flex">
      <div class="flex-col gap-8 flex">
        <div class="flex-col gap-4 flex">
          <div class="flex-col gap-1 flex">
            <p class="text-neutral-400 md:text-sm">
              {{ t(`faq.title`) }}
            </p>
            <div class="flex items-center gap-1">
              <h2 class="text-5xl font-bold md:text-3xl flex items-center gap-1">
                <dfn class="not-italic">
                  <abbr title="Frequently Asked Questions" class="no-underline">FAQ</abbr>
                </dfn>
                <span class="h-10 w-10 md:h-6 md:w-6 text-primary-darker">
                  <SvgSystemSMS />
                </span>
              </h2>
            </div>
          </div>
          <p class="text-xl md:text-lg">
            {{ t(`faq.description`) }}
          </p>
        </div>

        <div class="flex-col gap-3 flex">
          <FaqHeaderSection
            v-for="(section, index) in $tm('faq.categories')"
            :key="index"
            :faq="section"
          />
        </div>
      </div>
      <p class="text-l mt-8 md:text-base text-center">
        {{ t(`faq.contact`) }}
      </p>
    </div>
  </div>
</template>
