// chatSummary.js
import { useChatStore } from '@/store/chatStore';
import { fetcher } from '@/utils/useFetcher.js';

const apiUrl = import.meta.env.VITE_API_URL;

export async function getSummary(language) {
  const chatStore = useChatStore();
  const chatHistory = chatStore.chat.messages
  // Filter the chat history so that only entries with the role "USER" and "ASSISTANT" are retained
  const userChatHistory = chatHistory.filter(entry => entry.role === 'user' || entry.role === 'assistant');
  const requestUrl = new URL('/api/v1/features/chat_summary/?', apiUrl);
  requestUrl.searchParams.set('language', language);
  requestUrl.searchParams.set('plugin', 'chat_summary');

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json'
    },
    body: JSON.stringify({
      "chat_history": userChatHistory.map(({ role, content }) => ({ role, content })),
    })
  };

  try {
    const response = await fetcher(requestUrl, requestOptions);

    let fullResponseText = '';
    const reader = response.body.getReader();

    const handleMessageData = async () => {
      const { done, value } = await reader.read();
      if (!done) {
        const textData = new TextDecoder().decode(value);
        const cleanedText = textData.replace(/(data: |\n|\r)/g, '');

        fullResponseText += cleanedText;

        await handleMessageData();
      }
    };

    await handleMessageData();

    return fullResponseText;

  } catch (error) {
    console.error('Error:', error);
    return false
  }

}



