<template>
  <div class="h-8 w-8 flex justify-center items-center">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.55317 25.4248C9.0585 27.9123 12.4494 29.3027 15.9799 29.2901C18.0295 29.2915 20.0518 28.8193 21.8889 27.9103C23.726 27.0013 25.3282 25.6801 26.5705 24.0498C27.8127 22.4195 28.6615 20.5242 29.0505 18.5118C29.4396 16.4993 29.3583 14.4243 28.8132 12.4484C28.7592 12.2864 28.645 12.1513 28.4942 12.071C28.3435 11.9906 28.1677 11.9713 28.003 12.0168C27.8384 12.0623 27.6975 12.1693 27.6095 12.3157C27.5214 12.4621 27.4929 12.6366 27.5299 12.8034C28.1935 15.2168 28.0846 17.7773 27.2184 20.1256C26.3522 22.4739 24.7723 24.4919 22.7005 25.8962C20.6288 27.3006 18.1691 28.0208 15.6671 27.9557C13.165 27.8906 10.7461 27.0435 8.75018 25.5333C6.75421 24.0231 5.28137 21.9257 4.53848 19.5356C3.79559 17.1454 3.81996 14.5827 4.60817 12.2071C5.39638 9.83153 6.90883 7.76249 8.93316 6.29052C10.9575 4.81855 13.392 4.01758 15.8949 4.00008H15.9799C18.82 4.00199 21.5672 5.01261 23.7315 6.85175C23.8666 6.96601 24.0415 7.02195 24.2177 7.00726C24.305 6.99999 24.39 6.97559 24.4679 6.93547C24.5458 6.89535 24.6149 6.84028 24.6715 6.77341C24.7858 6.63838 24.8417 6.46348 24.827 6.28719C24.8123 6.11091 24.7282 5.94768 24.5932 5.83341C22.1872 3.79193 19.1352 2.66987 15.9799 2.66675H15.8849C12.3544 2.67935 8.97351 4.0939 6.486 6.59923C3.99849 9.10456 2.60809 12.4954 2.62069 16.0259C2.63329 19.5564 4.04785 22.9373 6.55317 25.4248ZM8.22158 15.8212L14.6466 22.2562L28.5349 8.37284C28.5969 8.31089 28.646 8.23736 28.6795 8.15643C28.713 8.0755 28.7303 7.98876 28.7303 7.90117C28.7303 7.81357 28.713 7.72683 28.6795 7.6459C28.646 7.56497 28.5969 7.49144 28.5349 7.4295C28.473 7.36756 28.3994 7.31843 28.3185 7.28491C28.2376 7.25138 28.1508 7.23413 28.0632 7.23413C27.9756 7.23413 27.8889 7.25138 27.808 7.28491C27.7271 7.31843 27.6535 7.36756 27.5916 7.4295L14.6516 20.3778L9.16491 14.8778C9.03981 14.7527 8.87015 14.6825 8.69324 14.6825C8.51633 14.6825 8.34667 14.7527 8.22158 14.8778C8.09648 15.0029 8.0262 15.1726 8.0262 15.3495C8.0262 15.5264 8.09648 15.6961 8.22158 15.8212Z"
        fill="#0D0D0D"
      />
    </svg>
  </div>
</template>
