import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import getFormattedTime from '@/utils/getFormattedTime.js';
import { ref } from 'vue';


/**
 * Retrieves the authentication type from the environment variables.
 *
 * @returns {string} The authentication type specified in the VITE_AUTHENTICATION_TYPE environment variable.
 */
const getAuthenticationType = () => import.meta.env.VITE_AUTHENTICATION_TYPE;

const authType = {
  KEYCLOAK: 'keycloak',
  SAML: 'saml',
}

console.log('🔍 authenticationType', getAuthenticationType());
console.log('💈', getFormattedTime(new Date()), 'userStore.js');

export const useUserStore = defineStore(
  'userStore',
  () => {
    // Try to restore the uuid for the sse from localStorage
    const storedSseUuid4 = getLocalUuidOrSetIt();
    const loggedInUser = ref(null);

    const setLoggedInUser = (userInformation) => {
      loggedInUser.value = userInformation;
    };

    const getUserInfoCopy = async (token, userStore) => {
      if (getAuthenticationType() === authType.KEYCLOAK) {
        return parseJwt(token);
      }
      return await userStore.getUserInfo(token);
    };

    const getUserInfo = async (token) => {
      if (!token) {
        console.error('No token provided');
        return;
      }
      // handle keycloak
      if (getAuthenticationType() === authType.KEYCLOAK) {
        return parseJwt(token);
      }
      // handle SAML
      if (getAuthenticationType() === authType.SAML) {
        try {
          const response = await fetch(
            `${import.meta.env.VITE_OAUTH2_BASE_URL}/oauth2/userInfo`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          return response.json();
        } catch (error) {
          console.error('Error fetching user info:', error);
        };
      };
      console.error('Unknown authentication type: "', getAuthenticationType(), '"');
    };


    return {
      storedSseUuid4,
      loggedInUser,
      setLoggedInUser,
      getUserInfo,
    };
  },
  { persist: true },
);

// Redirecting the user to the authorization server
function getLocalUuidOrSetIt() {
  let sseUuid = localStorage.getItem('sseUuid4');

  if (sseUuid !== null) {
    sseUuid = uuidv4();
    localStorage.setItem('sseUuid4', sseUuid);
  }

  return sseUuid;
}

/**
 * Parses a JWT token and returns the payload as a JSON object.
 *
 * @param {string} token - The JWT token, based on OpenID, to parse.
 * @returns {object|null} The payload of the JWT token as a JSON object, or null if the token cannot be parsed.
 */
export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    console.error('Error parsing JWT token:', e);
    return null;
  }
};
