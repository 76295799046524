<script setup>
  import { onMounted, onUnmounted } from 'vue';
  onMounted(() => {});
  onUnmounted(() => {});
</script>

<template>
  <router-view />
</template>

<style></style>
