<script setup>
  import { watch, ref } from 'vue';
  import SvgThumbsUp from '@/components/common/svg/svgThumbsUp.vue';
  import SvgThumbsDown from '@/components/common/svg/svgThumbsDown.vue';
  import { sendFeedback } from '@/services/chatFeedback';
  import { useChatStore } from '@/store/chatStore';
  import { useAppStore } from '@/store/appStore';
  import { storeToRefs } from 'pinia';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const chatStore = useChatStore();
  const appStore = useAppStore();
  const props = defineProps(['index']);

  const { currentLanguage } = storeToRefs(useAppStore());
  const thumbUpTipContent = ref('');
  const thumbDownTipContent = ref('');

  watch(
    currentLanguage,
    (selectedLanguage) => {
      thumbUpTipContent.value =
        selectedLanguage.name === 'english' ? 'Good Response' : 'Gute Antwort';
      thumbDownTipContent.value =
        selectedLanguage.name === 'english' ? 'Bad Response' : 'Schlechte Antwort';
    },
    { immediate: true },
  );

  // Filters the chat messages to receive only the user's messages up to a certain index
  function getFilteredChatHistory(messages, index) {
    return messages
      .slice(0, index + 1)
      .filter((message) => message.role === 'user' || message.role === 'assistant')
      .map((message) => ({
        role: message.role,
        content: message.content,
      }));
  }

  function getFilteredSources(messages, index) {
    return messages
      .slice(0, index + 1)
      .filter((message) => message.role === 'assistant' && message.sources)
      .map((message) => ({
        role: message.role,
        sources: message.sources,
      }));
  }

  let chatHistory = getFilteredChatHistory(chatStore.chat.messages, props.index);
  let chatSources = getFilteredSources(chatStore.chat.messages, props.index);

  const showModal = ref(false);
  const userText = ref('');
  const feedbackType = ref(null);

  // Processes the feedback provided by the user and sends it to the server
  function handleFeedback(score) {
    const rating = score === 'up' ? 'positive' : 'negative';
    const feedbackText = userText.value;
    const language = appStore.currentLanguage.name.toLowerCase();

    chatHistory = getFilteredChatHistory(chatStore.chat.messages, props.index);
    chatSources = getFilteredSources(chatStore.chat.messages, props.index);

    sendFeedback(language, chatHistory, rating, feedbackText, chatSources)
      .then((data) => {})
      .catch((error) => {
        console.error('Error when sending feedback:', error);
      });

    userText.value = '';
    showModal.value = false;
  }

  // Opens or closes the modal based on the passed type
  function handleModal(type) {
    feedbackType.value = type;
    showModal.value = !showModal.value;
  }
</script>

<template>
  <button
    v-tippy="{
      content: thumbUpTipContent,
      placement: 'bottom',
      arrow: true,
      animation: 'fade',
      theme: 'light',
    }"
    @click="() => handleModal('up')"
  >
    <SvgThumbsUp svgFill="black" :hoverActive="true" />
  </button>
  <button
    v-tippy="{
      content: thumbDownTipContent,
      placement: 'bottom',
      arrow: true,
      animation: 'fade',
      theme: 'light',
    }"
    @click="() => handleModal('down')"
  >
    <SvgThumbsDown svgFill="black" :hoverActive="true" />
  </button>

  <dialog class="modal" :class="{ 'modal-open': showModal }">
    <form
      class="flex flex-col items-start gap-6 rounded-lg bg-white w-156 p-4 h-64 font-VFRegular"
      method="dialog"
    >
      <div class="flex items-center gap-2 flex-1">
        <!-- Use the feedback type to dynamically select the logo -->
        <div v-if="feedbackType === 'down'">
          <SvgThumbsDown svgFill="#BD0000" :hoverActive="false" />
        </div>
        <div v-else>
          <SvgThumbsUp svgFill="#0a5c5c" :hoverActive="false" />
        </div>
        <!-- Dynamically select the message based on feedback type -->
        <div class="font-bold leading-5 text-text-primary">
          <p>
            {{ feedbackType === 'down' ? t(`feedback.negative`) : t(`feedback.positive`) }}
          </p>
        </div>
        <div class="font-normal leading-6 text-text-secondary">
          <p>(Optional)</p>
        </div>
      </div>
      <textarea
        v-model="userText"
        type="text"
        :placeholder="t(`feedback.placeholder`)"
        class="w-full max-w-l h-24 py-2 px-4 border border-default rounded-lg resize-none text-text-tertiary"
      />
      <div class="w-full">
        <div class="modal-action mt-0">
          <button class="btn bg-white text-monochrome-500 font-normal" @click="handleModal(null)">
            {{ t(`feedback.dismiss`) }}
          </button>
          <button
            class="btn bg-brand-primary text-monochrome-100 font-normal"
            @click="() => handleFeedback(feedbackType)"
          >
            {{ t(`feedback.submit`) }}
          </button>
        </div>
      </div>
    </form>
  </dialog>
</template>
