import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/router';
import './assets/css/styles.css';
import { createPinia } from 'pinia';
import 'vue-toastification/dist/index.css'; // todo change to own css and style
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { plugin as VueTippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'floating-vue/dist/style.css'
import axios from 'axios';
import FloatingVue from 'floating-vue'

import { createI18n } from 'vue-i18n';
import English from '@/locales/en.json';
import German from '@/locales/de.json';

const messages = {
  en: English,
  de: German
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages
});

axios.defaults.withCredentials = true;

const vue = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

vue.config.globalProperties.$msalInstance = {};

vue.use(router);
vue.use(pinia);
vue.use(i18n);
vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  component: 'tippy', // => <tippy/>
  componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
  defaultProps: {
    placement: 'bottom',
    allowHTML: true,
    theme: 'light',
  }, // => Global default options * see all props
});
vue.use(FloatingVue);


// Initialize the store, sets the access token, and prepares it for use.
// const client = useAuthStore().authClient;

vue.directive('click-outside', {
  beforeMount: function (element, binding) {
    //  check that click was outside the el and his children
    element.clickOutsideEvent = function (event) {
      // and if it did, call method provided in attribute value
      if (!(element === event.target || element.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent)
  },
  unmounted: function (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent)
  }
});

vue.mount('#app');
