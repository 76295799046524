<script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import ExitFlowPopup from "@/components/common/ExitFlowPopup.vue";

  const containerRef = ref(null);
  let observer;
  onMounted(() => {
    observer = new MutationObserver(() => {
      scrollToBottom();
    });
    observer.observe(containerRef.value, { childList: true, subtree: true });
    scrollToBottom();
  });

  onUnmounted(() => {
    observer.disconnect();
  });
  const scrollToBottom = () => {
    containerRef.value.scrollTop = containerRef.value.scrollHeight;
  };
</script>

<template>
  <div class="overflow-hidden h-full w-full">
    <div class="h-full w-full flex justify-center">
      <div
        ref="containerRef"
        class="scroll-smooth h-full w-full overflow-auto scrollbar-none flex justify-center"
        :style="{ transition: 'scroll-behavior 1.5s ease' }"
      >
        <slot name="messages"></slot>
      </div>
    </div>
  </div>
  <ExitFlowPopup></ExitFlowPopup>
</template>
