<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.3295 3.3295C3.54048 3.11853 3.82663 3 4.125 3H8.25C8.45711 3 8.625 2.83211 8.625 2.625C8.625 2.41789 8.45711 2.25 8.25 2.25H4.125C3.62772 2.25 3.15081 2.44754 2.79917 2.79917C2.44754 3.15081 2.25 3.62772 2.25 4.125V13.875C2.25 14.3723 2.44754 14.8492 2.79917 15.2008C3.15081 15.5525 3.62772 15.75 4.125 15.75H13.875C14.3723 15.75 14.8492 15.5525 15.2008 15.2008C15.5525 14.8492 15.75 14.3723 15.75 13.875V9.75C15.75 9.54289 15.5821 9.375 15.375 9.375C15.1679 9.375 15 9.54289 15 9.75V13.875C15 14.1734 14.8815 14.4595 14.6705 14.6705C14.4595 14.8815 14.1734 15 13.875 15H4.125C3.82663 15 3.54048 14.8815 3.3295 14.6705C3.11853 14.4595 3 14.1734 3 13.875V4.125C3 3.82663 3.11853 3.54048 3.3295 3.3295ZM15.6402 2.35983C15.6761 2.39579 15.7032 2.43723 15.7215 2.48146C15.7399 2.52567 15.75 2.57415 15.75 2.625V7.125C15.75 7.33211 15.5821 7.5 15.375 7.5C15.1679 7.5 15 7.33211 15 7.125V3.53033L10.3902 8.14016C10.2437 8.28661 10.0063 8.28661 9.85984 8.14016C9.71339 7.99372 9.71339 7.75628 9.85984 7.60984L14.4697 3H10.875C10.6679 3 10.5 2.83211 10.5 2.625C10.5 2.41789 10.6679 2.25 10.875 2.25H15.3749H15.375C15.381 2.25 15.387 2.25014 15.393 2.25043C15.4358 2.25247 15.4783 2.26181 15.5185 2.27845C15.5628 2.29675 15.6042 2.32388 15.6402 2.35983Z"
      fill="currentColor"
    />
  </svg>
</template>
