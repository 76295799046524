<script setup>
  import SvgQuestion from '@/components/common/svg/svgQuestion.vue';
  const props = defineProps(['followup']);
</script>
<template>
  <button
    class="inline-flex w-full h-full gap-2 p-2 text-left bg-white rounded-lg border border-neutral-200 items-center hover:bg-opacity-30 hover:bg-gray-500"
    type="button"
  >
    <SvgQuestion />
    <span class="text-sm tracking-tight font-['VodafoneRg']">
      {{ props.followup }}
    </span>
  </button>
</template>
