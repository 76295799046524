<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.25 20.5L7.75 12L16.25 3.5"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>
