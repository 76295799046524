<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9ZM9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5ZM7.48189 6.66116C7.44268 6.8633 7.2476 6.9962 7.04488 6.95832C6.8413 6.92028 6.7071 6.7244 6.74514 6.52082L7.11376 6.5897C6.74514 6.52082 6.74518 6.52061 6.74522 6.52039L6.74531 6.51992L6.7455 6.5189L6.74598 6.51647L6.74726 6.51008L6.7513 6.49145C6.75469 6.47652 6.75951 6.45661 6.76609 6.43244C6.77921 6.38418 6.79945 6.31839 6.82933 6.24089C6.88878 6.08671 6.98841 5.88101 7.15042 5.67439C7.48431 5.2486 8.06237 4.84819 9.00174 4.85251C9.99669 4.85709 10.6649 5.47959 10.9448 6.20942C11.2169 6.91878 11.148 7.80448 10.5896 8.37289C10.2032 8.76777 9.87241 9.09295 9.63762 9.32115C9.55458 9.40352 9.48863 9.50148 9.44356 9.60942C9.3983 9.71781 9.375 9.8341 9.37501 9.95155V10.6763C9.37501 10.8834 9.20712 11.0513 9.00001 11.0513C8.7929 11.0513 8.62501 10.8834 8.62501 10.6763V9.9516C8.625 9.73493 8.66798 9.52039 8.75146 9.32045C8.83495 9.1205 8.95728 8.9391 9.11137 8.78677L9.11365 8.78453C9.34561 8.5591 9.67232 8.23797 10.0539 7.84805L10.0544 7.84746C10.3555 7.54117 10.4407 6.9894 10.2446 6.47802C10.0562 5.98699 9.63364 5.60543 8.99829 5.60251C8.30734 5.59933 7.94228 5.88001 7.74061 6.13719C7.63485 6.27206 7.5686 6.40831 7.52911 6.51072C7.50953 6.5615 7.49704 6.60266 7.4898 6.62928C7.48619 6.64254 7.48392 6.65205 7.48276 6.65718L7.48189 6.66116ZM8.4375 12.75C8.4375 12.4393 8.68934 12.1875 9 12.1875C9.31066 12.1875 9.5625 12.4393 9.5625 12.75C9.5625 13.0607 9.31066 13.3125 9 13.3125C8.68934 13.3125 8.4375 13.0607 8.4375 12.75Z"
      fill="currentColor"
    />
  </svg>
</template>
