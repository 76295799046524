<template>
  <div class="h-4.5 w-4.5 flex justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.68625 14.3014C5.0955 15.7006 7.00287 16.4827 8.98876 16.4756C10.1417 16.4764 11.2792 16.2108 12.3126 15.6995C13.3459 15.1882 14.2472 14.445 14.946 13.528C15.6448 12.6109 16.1222 11.5448 16.341 10.4128C16.5599 9.28084 16.5142 8.1136 16.2075 7.00219C16.1771 6.91104 16.1129 6.83504 16.0281 6.78987C15.9433 6.74469 15.8444 6.73379 15.7518 6.7594C15.6592 6.78502 15.58 6.8452 15.5304 6.92753C15.4809 7.00987 15.4649 7.10807 15.4856 7.20188C15.8589 8.55938 15.7977 9.9997 15.3104 11.3206C14.8232 12.6415 13.9345 13.7766 12.7691 14.5666C11.6038 15.3566 10.2202 15.7617 8.81281 15.7251C7.4054 15.6884 6.0448 15.2119 4.92207 14.3624C3.79933 13.513 2.97086 12.3332 2.55299 10.9887C2.13511 9.64427 2.14882 8.2027 2.59219 6.86645C3.03555 5.53019 3.88631 4.36636 5.02499 3.53837C6.16367 2.71039 7.53309 2.25984 8.94095 2.25H8.98876C10.5864 2.25107 12.1317 2.81955 13.3491 3.85406C13.425 3.91834 13.5234 3.9498 13.6226 3.94154C13.6717 3.93745 13.7195 3.92373 13.7633 3.90116C13.8071 3.87859 13.846 3.84761 13.8778 3.81C13.9421 3.73404 13.9736 3.63566 13.9653 3.5365C13.957 3.43734 13.9097 3.34552 13.8338 3.28125C12.4804 2.13291 10.7637 1.50175 8.98876 1.5H8.93532C6.94943 1.50709 5.04769 2.30277 3.64847 3.71202C2.24924 5.12127 1.46714 7.02864 1.47423 9.01453C1.48132 11.0004 2.277 12.9022 3.68625 14.3014ZM4.62473 8.89936L8.23879 12.519L16.051 4.70967C16.0858 4.67483 16.1135 4.63347 16.1323 4.58795C16.1512 4.54243 16.1609 4.49363 16.1609 4.44436C16.1609 4.39509 16.1512 4.3463 16.1323 4.30078C16.1135 4.25525 16.0858 4.21389 16.051 4.17905C16.0161 4.14421 15.9748 4.11657 15.9293 4.09771C15.8837 4.07886 15.8349 4.06915 15.7857 4.06915C15.7364 4.06915 15.6876 4.07886 15.6421 4.09771C15.5966 4.11657 15.5552 4.14421 15.5204 4.17905L8.2416 11.4625L5.15535 8.36874C5.08499 8.29837 4.98955 8.25884 4.89004 8.25884C4.79053 8.25884 4.69509 8.29837 4.62473 8.36874C4.55436 8.4391 4.51483 8.53454 4.51483 8.63405C4.51483 8.73356 4.55436 8.829 4.62473 8.89936Z"
        fill="#3A3A3A"
      />
    </svg>
  </div>
</template>
