<script setup>
  import { defineProps } from 'vue';
  import { Role } from '@/store/chatStore';
  import ChatBubbleLink from '@/components/chat/ChatBubbleLink.vue';
  import copyButton from '@/components/common/copyButton.vue';
  import thumbsView from '@/components/common/thumbsView.vue';
  import OnboardingTooltip from "@/components/common/OnboardingTooltip.vue";
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const roleStyles = {
    [Role.ASSISTANT]: 'bg-monochrome-100 text-text-primary border-monochrome-250 rounded-tl-none',
    [Role.USER]: 'bg-neutral-700 text-[#F7F7F8] border-monochrome-250 rounded-tr-none',
  };

  const props = defineProps(['messageRole', 'text', 'sources', 'index']);

  const copyText = () => {
    navigator.clipboard.writeText(props.text);
  };
</script>

<template>
  <div class="flex-row">
    <div
      :class="roleStyles[props.messageRole]"
      class="border p-4 text-base font-normal font-['VodafoneRg'] rounded-xl whitespace-pre-wrap"
    >
      <div class="flex-row gap-10">
        <div class="block">
          <span>{{ props.text }}</span>
        </div>
        <template v-if="props.sources && props.sources.length > 0">
          <OnboardingTooltip tooltip_id="sourceIndicator"
                             :heading="t('sourceIndicatorHeading')"
                             :content="t('sourceIndicatorContent')"
                             placement="auto">
            <ChatBubbleLink :sources="props.sources" />
          </OnboardingTooltip>
        </template>
      </div>
    </div>
    <div v-if="props.messageRole === Role.ASSISTANT">
      <div class="flex mt-1">
        <copyButton :textToCopy="props.text" :onClick="copyText" />
        <thumbsView :index="props.index" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .link-tooltip {
    position: relative;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 0px;
    z-index: 0;
    display: inline;
    visibility: hidden;
  }

  .link-button:hover + .link-tooltip {
    visibility: visible;
  }
</style>
