<script setup>
    import {computed, onMounted, ref, watch} from "vue";
    import SvgClose from "@/components/common/svg/svgClose.vue";
    import {useChatStore} from "@/store/chatStore.js";
    const popup = ref(null);
    const chatStore = useChatStore();
    const showUserExitFlow = computed(() => chatStore.chat.showUserExitFlow);

    onMounted(() => {
      popup.value = document.getElementById('exit-flow-popup');
      if (showUserExitFlow.value) {
        showPopup();
      }
    });

    watch(showUserExitFlow, (shouldShowPopup) => {
      if (shouldShowPopup) {
        showPopup();
      } else {
        hidePopup();
      }
    }, { immediate: true });


    function showPopup() {
      if (popup.value) {
        popup.value.classList.remove('hidden');
      }
    }

    function hidePopup() {
      if (popup.value) {
        popup.value.classList.add('hidden');
        chatStore.updateShowUserExitFlow(false);
      }
    }
</script>

<template>
  <div id="exit-flow-popup" @hidePopup="hidePopup" class="hidden bg-white shadow-md rounded-lg w-2/5 lg:w-1/5 sm:w-4/5 py-3.5 px-2 z-10 absolute right-10 bottom-28 sm:right-10 sm:left-10">
    <div class="flex flex-col gap-2 justify-start m-3 font-VFRegular">
      <h2 class="flex flex-row justify-between items-center font-VFBold text-md">
        <span >{{ $t('exitFlowPopUp.title') }}</span>
        <svg-close @click="hidePopup" class="hover:bg-gray-100"/>
      </h2>
      <p>
        {{ $t('exitFlowPopUp.content') }}
        <ul>
          <li v-html="$t('exitFlowPopUp.content_bullet_1')"></li>
          <li v-html="$t('exitFlowPopUp.content_bullet_2')"></li>
        </ul>
      </p>
    </div>
  </div>
</template>

<style scoped>
  li:before {
    content: '•';
    margin-right: 7px;
  }
  li a {
    @apply text-red-700 underline;
  }
</style>
