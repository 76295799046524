<script setup>
import { useI18n } from 'vue-i18n';
import slide1 from '@/assets/images/slide1.png';
import slide2 from '@/assets/images/slide2.png';
import slide3 from '@/assets/images/slide3.png';
import slide4 from '@/assets/images/slide4.png';
import {ref} from "vue";
import SvgArrowRight from "@/components/common/svg/svgArrowRight.vue";
import SvgArrowLeft from "@/components/common/svg/svgArrowLeft.vue";

const { t } = useI18n();

const emit = defineEmits(['close'])

const props = defineProps({
  show: Boolean
});
let counter = ref(1);
let slides = ref([
  {
    headerImage: slide1,
    title: t('onboarding_slides.slide1.title'),
    content: t('onboarding_slides.slide1.content'),
  },
  {
    headerImage: slide2,
    title: t('onboarding_slides.slide2.title'),
    content: t('onboarding_slides.slide2.content'),
  },
  {
    headerImage: slide3,
    title: t('onboarding_slides.slide3.title'),
    content: t('onboarding_slides.slide3.content'),
  },
  {
    headerImage: slide4,
    title: t('onboarding_slides.slide4.title'),
    content: t('onboarding_slides.slide4.content'),
  },
]);

function next() {
  if (counter.value === slides.value.length) {
    emit('close');
    return;
  }

  const first = slides.value.shift();
  slides.value = slides.value.concat(first);
  counter.value++;
}

function previous() {
  if (counter.value === 0) return;
  const last = slides.value.pop();
  slides.value = [last].concat(slides.value);
  counter.value--;
}
</script>

<template>
  <Transition name="modal">
    <div v-if="props.show" class="modal-mask">
      <div class="modal-container">
        <button class="flex justify-end" v-if="counter !== slides.length" @click="$emit('close')">{{ t('skip') }}</button>
        <transition-group class="flex justify-items-center items-center overflow-hidden" tag="div">
          <div class="flex flex-[0_0_100%] justify-center transition-all duration-300" v-for="(item, index) in slides" :key="index">
            <div class="flex flex-col justify-start items-start gap-4 w-full font-VFRegular">
              <img class="w-full" :src="item.headerImage" :alt="item" />
              <h1 class="font-VFBold text-2xl" v-html="item.title"></h1>
              <p class="text-begin text-base">{{ item.content }}</p>
            </div>
          </div>
        </transition-group>
        <!-- Modal Controls -->
        <div class='flex flex-row justify-between items-center w-full mt-8'>
          <div class="w-32">
            <button class='border-black border bg-white text-white p-2.5 rounded-lg' v-if="counter !== 1" @click="previous"><SvgArrowLeft /></button>
          </div>
          <div class="flex flex-row gap-3">
            <div v-for="(_, index) in slides" :key="index" class="w-2.5 h-2.5 rounded-full bg-brand-primary" :class="{ 'bg-gray-300': counter !== index + 1 }"></div>
          </div>
          <div class="flex flex-row justify-end w-32">
            <button class='flex flex-row gap-1 font-VFRegular text-base bg-brand-primary text-white p-2.5 rounded-lg' @click="next"><span class="pl-1.5" v-if="counter === slides.length">{{ t('getStarted') }}</span><SvgArrowRight fill-color="white"/></button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-mask {
  @apply flex top-0 left-0 w-full h-full bg-gray-950/50 transition-opacity duration-300 ease-in-out z-[9998] fixed;
}

.modal-container {
  @apply rounded-lg w-2/6 sm:w-11/12 flex flex-col gap-3 m-auto px-6 py-8 shadow-lg transition-all duration-300 ease-in-out bg-white;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
