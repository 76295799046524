<script setup>
  import { logout } from '@/auth/authUtils.js';
  import { FeatureToggleComponent as feature } from 'vue-feature-toggle';
  import router from '@/router/router.js';
  import svgConversation from './svg/svgConversation.vue';
  import svgMagicStickSystem from './svg/svgMagicStickSystem.vue';
  import svgPopOutSystem from './svg/svgPopOutSystem.vue';
  import svgPrivacySystem from './svg/svgPrivacySystem.vue';
  import svgContact from './svg/svgContact.vue';
  import svgLogout from './svg/svgLogout.vue';
  import { useI18n } from 'vue-i18n';

  const props = defineProps(['menuVisible', 'parentSource']);
  const emit = defineEmits(['closeMenu']);
  const { t } = useI18n();
  const pages = [
    {
      title: 'faq',
      icon: svgConversation,
      path: 'faq',
    },
    {
      title: 'howItWorks',
      icon: svgMagicStickSystem,
      path: '/',
    },
    {
      title: 'growYourAISkills',
      icon: svgPopOutSystem,
      path: '/',
    },
    {
      title: 'contact',
      icon: svgContact,
      path: '/',
    },
    {
      title: 'usagePolicy',
      icon: svgPrivacySystem,
      path: '/',
    },
  ];
  if (props.parentSource == 'sideBar') {
    pages.push({
      title: 'logout',
      icon: svgLogout,
      path: 'logout',
    });
  }

  function handleRouting(path) {
    if (path === 'logout') {
      logout();
    } else {
      router.push({ name: path });
    }
    emit('closeMenu');
  }

  function debounce(func, delay) {
    let timeoutId = null;

    const debounced = function (...args) {
      const later = () => {
        timeoutId = null;
        func.apply(this, args);
      };

      clearTimeout(timeoutId);
      timeoutId = setTimeout(later, delay);
    };

    return debounced;
  }

  const debouncedExecuteFunction = debounce(handleRouting, 1000);
</script>

<template>
  <Transition>
    <form
      v-show="props.menuVisible"
      role="menu"
      aria-labelledby="menuButton"
      aria-orientation="vertical"
      class="absolute p-3 z-10 rounded-md shadow-lg focus:outline-none text-sm bg-white ring-white text-text-secondary dark:bg-monochrome-500 dark:text-monochrome-100"
    >
      <template v-for="(page, index) in pages" :key="index">
        <feature :name="page.title">
          <hr v-if="page.title == 'logout'" class="my-2 border-t border-text-secondary" />
          <label
            :for="page.title"
            :id="`page-${index}`"
            class="flex gap-3 items-center px-4 py-3 h-11 rounded-lg cursor-pointer hover:bg-monochrome-200 dark:hover:bg-[#565656]"
            role="menuitem"
            :to="page.path"
          >
            <component
              :is="page.icon"
              class="w-[18px] h-[18px]"
              v-if="props.parentSource != 'sideBar'"
            />
            <input
              :id="page.title"
              @change.stop.prevent="debouncedExecuteFunction(page.path)"
              type="radio"
              name="page"
              class="sr-only"
            />
            <span> {{ t(`pages.${page.title}`) }} </span>
          </label>
        </feature>
      </template>
    </form>
  </Transition>
</template>

<style scoped>
  label:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
