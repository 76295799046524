<template>
  <div class="h-10 w-10">
    <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0939 35.9245C27.9412 35.9245 35.924 27.9417 35.924 18.0943C35.924 10.9799 31.7573 4.83868 25.731 1.97792C22.4866 2.65096 19.9046 5.67038 19.916 9.09598C19.9164 9.20906 19.9265 9.3302 19.9377 9.38643C25.3669 10.7089 27.8315 13.9846 27.8466 18.5175C27.8616 23.0501 24.2831 28.0164 18.2351 28.0365C13.3361 28.0528 8.23933 23.8715 8.21705 17.1577C8.20235 12.7178 10.5976 8.44406 13.6595 5.90865C16.6462 3.43565 20.7375 1.84866 24.4483 1.83621C24.9252 1.8347 25.4239 1.87446 25.7298 1.97738C23.4152 0.878806 20.8263 0.26416 18.0939 0.26416C8.24658 0.26416 0.263672 8.24702 0.263672 18.0943C0.263672 27.9417 8.24658 35.9245 18.0939 35.9245Z"
        fill="#E60000"
      />
    </svg>
  </div>
</template>
