<script setup>
const props = defineProps({
  fillColor: {
    type: String,
    default: '#3A3A3A',
  },
});
</script>

<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9277 3.73629C11.123 3.54103 11.4395 3.54103 11.6348 3.73629C11.8301 3.93155 11.8301 4.24814 11.6348 4.4434L3.98836 12.0898H21.5312C21.8074 12.0898 22.0312 12.3137 22.0312 12.5898C22.0312 12.866 21.8074 13.0898 21.5312 13.0898H3.98836L11.6348 20.7363C11.8301 20.9316 11.8301 21.2481 11.6348 21.4434C11.4395 21.6387 11.123 21.6387 10.9277 21.4434L2.42921 12.9449C2.4282 12.9439 2.42719 12.9429 2.42619 12.9419L2.07415 12.5898L2.42619 12.2378C2.42719 12.2368 2.4282 12.2358 2.42921 12.2348L10.9277 3.73629Z"
          :fill="props.fillColor"/>
  </svg>
</template>
