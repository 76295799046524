<script setup>
  import SvgClose from "@/components/common/svg/svgClose.vue";

  const props = defineProps({
    heading: String,
    content: String,
    closePopup: Function,
  });
</script>

<template>
  <div class="flex flex-col justify-start m-3 font-VFRegular">
    <div class="flex flex-row justify-between">
      <h1 class="font-VFBold text-2xl">{{ props.heading }}</h1>
      <button v-if="closePopup" @click="closePopup" class="hover:bg-gray-100" type="button"><svg-close /></button>
    </div>
    <p class="text-base text-start">{{ props.content }}</p>
  </div>
</template>
