import { useChatStore, Status } from '@/store/chatStore';

export const handleChatData = async (response, assistantMessageId) => {
  const chatStore = useChatStore();
  let fullResponseText = '';
  const reader = response.body.getReader();
  const textDecoder = new TextDecoder();
  let statusSet = false; // Variable to track if status has been set

  const handleMessageData = async () => {
    try {
      const { done, value } = await reader.read();

      if (!done) {

        const textData = textDecoder.decode(value);
        const cleanedText = textData.replace(/(data: |\n|\r)/g, '');

        fullResponseText += cleanedText;

        chatStore.updateMessageContentById(assistantMessageId, fullResponseText);
        if (!statusSet) {
          chatStore.setCurrentStatus(assistantMessageId, Status.SUCCESS);
          statusSet = true; // Set statusSet to true after the first update
        }

        await handleMessageData();
      }
    } catch (error) {
      console.error('Error handling message data:', error);
      // Notify the user
      chatStore.addSystemMessage(`An error occurred: ${error.message}`);
      // Rethrow the error
      throw error;
    }
  };

  try {
    await handleMessageData();
  } catch (error) {
    // Handle the error at a higher level, if necessary
    console.error('Error in handleChatData:', error);
  }
};
