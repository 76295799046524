<script setup>
  const props = defineProps({
    entries: Object,
  });
</script>

<template>
  <div
    v-for="(response, index) in props.entries.response"
    :key="index"
    class="px-6 py-4 rounded-lg border border-default bg-white cursor-pointer details-container"
  >
    <details>
      <summary class="flex justify-between" id="{{ `Question-${key}` }}">
        {{ props.entries.query }}
      </summary>
    </details>
    <p class="text-[#737373] content pt-0" aria-describedby="{{ `Question-${key}` }}">
      {{ response }}
    </p>
  </div>
</template>

<style scoped>
  .details-container:has(details[open]) {
    display: flex;
    flex-direction: column;
  }
  .details-container:hover:has(details:not([open])) {
    @apply bg-monochrome-200;
  }

  summary::after {
    content: '';
    width: 24px;
    height: 24px;
    background: url('../../assets/icons/chevron-down-system.svg');
    transition: transform 1s;
  }

  details[open] > summary::after {
    transform: rotateX(180deg);
  }

  details[open] + p {
    padding-top: 8px;
  }
  details + .content {
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition: all 1s ease-in-out;
  }

  details[open] + .content {
    opacity: 1;
    max-height: 300px;
  }
</style>
