<script setup>
  import { ref } from 'vue';
  import { sendChatMessage } from '@/services/chatFeatures';
  import ChatFollowup from '@/components/chat/ChatFollowup.vue';
  import { useChatStore, Role, Status, createMessage } from '@/store/chatStore.js';
  import { useAppStore } from '@/store/appStore';
  import OnboardingTooltip from "@/components/common/OnboardingTooltip.vue";
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const chatStore = useChatStore();
  const appStore = useAppStore();
  const selectedFollowup = ref(null);

  async function handleFollowupClick(followup) {
    selectedFollowup.value = followup;

    try {
      const message = createMessage({
        role: Role.USER,
        content: followup,
        status: Status.FINISHED,
        plugin: appStore.currentPlugin.name,
      });
      chatStore.addMessage(message);

      const success = await sendChatMessage(followup);
      console.log(success ? 'Message sent successfully.' : 'Message could not be sent.');
    } catch (error) {
      console.error('Error sending the message:', error);
    }
  }
</script>

<template>
  <OnboardingTooltip tooltip_id="followUpQuestions"
                     :heading="t('followUpChatHintHeading')"
                     :content="t('followUpChatHintContent')"
                     placement="top">
    <ol class="flex flex-wrap gap-3 mb-3">
      <li v-for="(followup, index) in chatStore.chat.followups" :key="index">
        <ChatFollowup :followup="followup" @click="() => handleFollowupClick(followup)" />
      </li>
    </ol>
  </OnboardingTooltip>
</template>
