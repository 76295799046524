<script setup>
  import FaqQuestionItem from '@/components/faq/FaqQuestionItem.vue';

  const props = defineProps({
    faq: Object,
  });
</script>
<template>
  <div class="flex flex-col gap-3">
    <h3 class="text-xl md:text-lg">
      {{ faq.title }}
    </h3>

    <FaqQuestionItem
      v-for="(entries, index) in props.faq.entries"
      :key="index"
      :entries="entries"
    />
  </div>
</template>
