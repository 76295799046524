import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { getPluginFromAPI } from '@/services/plugin';

export const usePluginStore = defineStore(
  'pluginStore',
  () => {
    const plugins = ref([]);

    function addPlugin(plugin) {
      plugins.value.push(plugin);
    }

    const removePlugin = (plugin) => {
      plugins.value = plugins.value.filter((p) => p !== plugin);
    };

    const init = async () => {
      if (plugins.value.length === 0) {
        const apiPlugins = await getPluginFromAPI();
        plugins.value = apiPlugins;
      }
    };

    // Filter the plugins to get only those that are activated and whose function is not 'chat_summary' or 'document_search'
    const filteredPlugins = computed(() =>
      plugins.value.filter(
       (plugin) => plugin.feature.type !== 'chat_summary'
      )
    );


    return { plugins, addPlugin, removePlugin, init, filteredPlugins};
  },
  { persist: true },
);
