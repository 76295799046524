<script setup>
  import { defineProps } from 'vue';

  const props = defineProps([
    'head',
    'tail',
    'title',
    'subTitle',
    'isSelected',
    'headerIconColor',
    'tickIconColor',
  ]);
</script>

<template>
  <div
    class="flex gap-2 px-2 py-3 rounded-lg border duration-200 cursor-pointer items-center"
    :class="{
      'border border-monochrome-250 bg-monochrome-200 rounded-lg': props.isSelected,
      'bg-monochrome-100 border-monochrome-200 hover:border-monochrome-250 hover:bg-monochrome-200':
        !props.isSelected,
    }"
  >
    <div
      v-if="props.head"
      class="w-12 h-12 shrink-0 aspect-square bg-[#0a5c5c1a] rounded flex items-center justify-center"
    >
      <component :is="props.head" :color="props.headerIconColor" />
    </div>

    <div class="flex min-w-[24ch] flex-col flex-grow text-left text-sm text-text-secondary">
      <span>
        {{ props.title }}
      </span>
      <span class="text-[11px] font-normal text-[#828282]">
        {{ props.subTitle }}
      </span>
    </div>

    <div class="h-12 min-w-[18px] shrink-0 flex items-center">
      <component v-if="props.isSelected" :is="props.tail" :color="props.tickIconColor" />
    </div>
  </div>
</template>
