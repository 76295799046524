function getFormattedTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes().toString().padStart(2, '0');

  // Pad the minutes with a 0 if they are less than 10
  // minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${minutes}`;
}

export default getFormattedTime;
