<script setup>
  import { useChatListStore } from '@/store/chatListStore';
  import ChatListItem from '@/components/chat/ChatListItem.vue';
  import { useI18n } from 'vue-i18n';
  import { computed } from 'vue';
  const { t } = useI18n();

  const chatListStore = useChatListStore();
  const todaysChats = computed(() => {
    const todaysChats = chatListStore.chats.filter((chat) => new Date(Date.parse(chat.lastMessageAt)).toDateString() === new Date().toDateString());
    return todaysChats.filter((chat) => !chat.pinned).slice().reverse();
  });
  const pastChats = computed(() => {
    const pastChats = chatListStore.chats.filter((chat) => new Date(Date.parse(chat.lastMessageAt)).toDateString() !== new Date().toDateString());
    const notPinnedPastChats = pastChats.filter((chat) => !chat.pinned).slice().reverse();
    return Object.groupBy(notPinnedPastChats, ({lastMessageAt}) => new Date(Date.parse(lastMessageAt)).toLocaleDateString('de-DE'));
  });
  const maxPinnedChats = 4;
</script>

<template>
  <div class="mt-3.5" v-if="chatListStore.chats.filter((chat) => chat.pinned).length > 0">
    <div class="text-zinc-500 text-base font-normal font-['VodafoneRg'] leading-normal">
      {{ t(`sidebar.pinned`, [ chatListStore.chats.filter((chat) => chat.pinned).length, maxPinnedChats ]) }}
    </div>

    <ol>
      <li v-for="chat in chatListStore.chats.filter((chat) => chat.pinned).slice().reverse()" :key="chat.id">
        <ChatListItem :chat="chat" />
      </li>
    </ol>
  </div>

  <div class="mt-3.5">
    <div v-if="todaysChats.length" class="text-zinc-500 text-base font-normal font-['VodafoneRg'] leading-normal">
      {{ t(`sidebar.title`) }}

      <ol>
        <li v-for="chat in todaysChats" :key="chat.id">
          <ChatListItem :chat="chat" :maxPinnedChats="maxPinnedChats" />
        </li>
      </ol>
    </div>

    <div v-for="[date, chats] of Object.entries(pastChats)" class="text-zinc-500 text-base font-normal font-['VodafoneRg'] leading-normal">
      {{ date }}
      <ol>
        <li v-for="chat in chats" :key="chat.id">
          <ChatListItem :chat="chat" :maxPinnedChats="maxPinnedChats" />
        </li>
      </ol>
    </div>
  </div>
</template>
